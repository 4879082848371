import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { noop } from 'rxjs';
import { LoggingService } from './logging.services';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toast: any;

  constructor(
    private toastController: ToastController,
    private loggingService: LoggingService
  ) {
  }

  async presentToast(msg: string, classes?: string, toastPosition?: 'top' | 'bottom' | 'middle', durationTime = 2000) {
    if (!toastPosition) {
      toastPosition = 'bottom';
    }
    this.toast = await this.toastController.create({
      message: msg,
      duration: durationTime,
      cssClass: classes + ' toast-custom',
      position: toastPosition
    });
    this.toast.present();
  }

  presentErrorToast(err: any) {
    this.loggingService.logMessage(err, 'presentErrorToast');
    if (err && err.error && err.error.message) {
      this.presentToast(err.error.message);
    }
  }

  async removeTopToast() {
    await this.toastController.dismiss().catch(noop);
  }
}
