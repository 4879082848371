import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthUserData } from '../interface';
import { environment } from 'src/environments/environment';
import { NavigateService } from './navigate.service';
import { LOGIN_PAGE } from '../routes-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authKey: string | null = null;
  userDetails: AuthUserData = {
  };
  userName: string | undefined;
  isLoggedIn = new BehaviorSubject<boolean>(false);
  constructor(private navigateService: NavigateService) {
    this.getAuthentication();
  }

  setAuthentication(userDetails: AuthUserData) {
    this.isLoggedIn.next(true);
    this.authKey = userDetails.token || null;
    localStorage.setItem(
      environment.localStorageKeys.user,
      JSON.stringify(userDetails)
    );
  }
  getAuthentication() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.userDetails = JSON.parse(localStorage.getItem(environment.localStorageKeys.user)!);
    this.authKey = this.userDetails?.token || null;
    if (this.userDetails) {
      this.userName = this.userDetails.user?.name;
      this.isLoggedIn.next(true);
    } else {
      this.isLoggedIn.next(false);
    }
  }


  clearStorageData(key: string) {
    localStorage.removeItem(key);
  }

  logOut(): void {
    localStorage.clear();
    this.isLoggedIn.next(false);
    this.authKey = null;
    this.userName = '';
    this.navigateService.navigateForward([LOGIN_PAGE]);
  }


}
