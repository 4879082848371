import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Utilities } from '../../classes/utilities';
import { FilterContext } from '../../interface';
import { DataService } from '../../services/data.service';
import { ComponentType } from '../../shared.enum';

@Component({
  selector: 'app-farmer-filter',
  templateUrl: './farmer-filter.component.html',
  styleUrls: ['./farmer-filter.component.scss'],
})
export class FarmerFilterComponent implements OnInit {
  @Input() whichComponent: any;
  @Output() applyFilters = new EventEmitter();
  componentType  = ComponentType;
  selectedLocation = '';
  selectedProduct = '';
  selectProduct: any = {};
  selectCity ='';
  filterContent: FilterContext = {
    cities: [],
    popularProducts: [],
    productList: []
  };
  constructor(
    private dataService: DataService,
    public modalController: ModalController
    ) { }

  ngOnInit() {
    this.getFilterContext();
  }

  getFilterContext(){
    this.dataService.getFilterContext().subscribe((success: any)=>{
      if(success.data.context){
        this.filterContent = success.data.context;
      }
    });
  }

  applyProductValue(product: any){
    this.selectProduct = product.detail.value;
  }

  applyCityValue(location: any){
    this.selectCity = location.detail.value;
  }

  applyFilter(){
    this.modalController.getTop().then((modal: any) => {
      if(modal){
        this.modalController.dismiss({
          city: this.selectCity,
          productId: this.selectProduct.productId,
          productName: this.selectProduct.name
        });
      }else{
        this.applyFilters.emit({data: {
          city: this.selectCity,
          productId: this.selectProduct.productId,
          productName: this.selectProduct.name
        }});
      }
    });
  }

  ionViewWillEnter() {
   Utilities.addClassNameByTagName('body', 'hide-footer');
  }

  ionViewWillLeave() {
    Utilities.removeClassNameByTagName('body', 'hide-footer');
  }
}
