import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ANDROID } from '../../constant';
import { Utilities } from '../../classes/utilities';

@Component({
  selector: 'app-whatsapp-alert',
  templateUrl: './whatsapp-alert.component.html',
  styleUrls: ['./whatsapp-alert.component.scss'],
})
export class WhatsappAlertComponent implements OnInit {
  public androidAppOrNot = false;
  constructor(
    private modalController: ModalController,
    private socialSharing: SocialSharing,
    private platform: Platform) { }

  ngOnInit() {
    if (Utilities.isApp() && this.platform.is(ANDROID)) {
      this.androidAppOrNot = true;
    }
  }
  ionViewWillEnter() {
   Utilities.addClassNameByTagName('body', 'hide-footer');
  }
  ionViewWillLeave() {
    Utilities.removeClassNameByTagName('body', 'hide-footer');
  }

  cancel() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  whatsAppRedirection() {
    this.socialSharing.shareViaWhatsAppToReceiver('+918448871006', 'Hello OK India');
  }

}
