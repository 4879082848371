import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AddressService } from 'src/app/profile/edit-address/services/address.service';
import { SubscriptionComponent } from '../component/subscription/subscription.component';
import { FIREBASE_MASTER_DATA } from '../constant';
import { User } from '../interface';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { RegisterPluginService } from './register-plugin.service';


@Injectable({
  providedIn: 'root',
})
export class DataService {
  uuid: string | undefined;
  userProfile: User = {};
  firebaseMasterData: any = FIREBASE_MASTER_DATA;
  inAppPushNotificationShow = false;
  activeSubscription: any = {
    products: [],
    mapper: {},
  };
  knockKnock: Subject<any> = new Subject();
  constructor(
    private http: HttpService,
    private authService: AuthService,
    private registerPluginService: RegisterPluginService,
    private modalCtrl: ModalController,
    private addressService: AddressService
  ) { }

  reSetProfileData() {
    this.activeSubscription = {
      products: [],
      mapper: {},
    };
    this.userProfile = {};
  }

  getUserProfile(user?: User) {
    if (this.authService.authKey) {
      if (user) {
        this.userProfile = user;
        // eslint-disable-next-line no-underscore-dangle
        this.registerPluginService.doRegisterPlugin(this.userProfile);
        this.knockKnock.next(false);
      } else {
        this.http.get('api/user').subscribe((success: any) => {
          if (success?.data?.user) {
            this.userProfile = success.data.user;
            // eslint-disable-next-line no-underscore-dangle
            this.registerPluginService.doRegisterPlugin(this.userProfile);
            this.knockKnock.next(true);
          }
        });
      }
      this.getActiveSubscription();
      this.addressService.addresses();
    } else {
      this.knockKnock.next(true);
    }
  }

  getActiveSubscription() {
    this.activeSubscription = {
      products: [],
      mapper: {},
    };
    this.http.get(`api/subscription-list`).subscribe(
      (res: any) => {
        const response = res.data.subscriptions;
        if (response.length) {
          response.forEach((element: any) => {
            element.variants?.forEach((item: any) => {
              // eslint-disable-next-line no-underscore-dangle
              if (element.variantId === item._id) {
                element.subscribedVariant = item;
              }
            });
            const mapper = {
              // eslint-disable-next-line no-underscore-dangle
              [element.productId]: element._id,
            };
            this.activeSubscription.mapper = Object.assign(
              this.activeSubscription.mapper,
              mapper
            );
            this.activeSubscription.products = response;
          });
        }
      },
      (err) => {}
    );
  }
  async subscribeProduct(product: any) {
    // eslint-disable-next-line no-underscore-dangle
    const productId = product._id;
    let componentProps = {};
    if (
      this.activeSubscription.mapper &&
      productId in this.activeSubscription.mapper
    ) {
      componentProps = {
        subscriptionId: this.activeSubscription.mapper[productId],
      };
    } else {
      componentProps = { product };
    }
    const subscribeModal = await this.modalCtrl.create({
      component: SubscriptionComponent,
      animated: true,
      id: 'subscription-component',
      cssClass: 'subscription',
      componentProps,
    });
    subscribeModal.onDidDismiss().then((data: any) => {
      if (data?.data?.dismissed) {
        this.getActiveSubscription();
      }
    });
    return await subscribeModal.present();
  }

  getSuperSaving() {
    return this.http.get('api/cart/super-saving');
  }

  getFilterContext(){
    return this.http.get('api/vendor/filter-context');
  }

  postFarmerComment(dataToSend: any){
    return this.http.post('api/vendor/comment', dataToSend);
  }
}
