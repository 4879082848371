import { Component, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'app-lowest-price',
  templateUrl: './lowest-price.component.html',
  styleUrls: ['./lowest-price.component.scss'],
})
export class LowestPriceComponent {
  @Input() public title = '';
  @Input() public image: string | undefined;
  @Input() public url: string | undefined;
  constructor(
    private navigateService: NavigateService,
    private modalService: ModalService
  ) { }


  goToPage(url: string) {
    if (/product-detail/i.test(url)) {
      const productId = url.split('id=');
      this.modalService.productDetail(productId[1]);
    }else{
      this.navigateService.navigateByUrl(url);
    }
  }

}
