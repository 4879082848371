import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HEADER_CONFIG } from 'src/app/shared/constant';
import { HeaderConfig } from 'src/app/shared/interface';
import { NavigateService } from 'src/app/shared/services/navigate.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent  {
  previousHeader: HeaderConfig = HEADER_CONFIG.noHeader;
  previousFooter = false;
  constructor(
    public modalController: ModalController,
    private navigateService: NavigateService,
    ){
  }
  ionViewWillEnter(){
    this.previousHeader = this.navigateService.header;
    this.previousFooter = this.navigateService.footer;
    this.navigateService.header = HEADER_CONFIG.frequentlyAskedQuestion;
    this.navigateService.footer = false;
  }

  ionViewWillLeave(){
    this.navigateService.header = this.previousHeader;
    this.navigateService.footer = this.previousFooter;
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
