import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartService } from '../../../cart/services/cart.service';
import { StorageService } from '../../../shared/services/storage.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SuperOkService } from './superok.service';

@Injectable({
  providedIn: 'root'
})
export class SuperCartService {
  deliveryProducts = new BehaviorSubject<any>([]);
  deliveryProductVarient = new BehaviorSubject<any>({});
  deliveryId: string | undefined;
  isEditDelivery = false;
  isAddItems = false;
  constructor(
    public superOkService: SuperOkService,
    public toastService: ToastService,
    public localStorageService: StorageService
  ) {
    this.deliveryProducts.subscribe((res) => {
      let cpvObject = res.reduce((cpv: any, pv: any) => {
        cpv[`${pv.productId}-${pv.variantId}`] = pv.quantity;
        // this.cartQuantity += pv.quantity;
        return cpv;
      }, {});
      cpvObject = cpvObject ? cpvObject : {};
      this.deliveryProductVarient.next(cpvObject);
    });
  }

  public deliveryProductQuantity(productId: string, varientId: string) {
    return this.deliveryProductVarient.value[productId + '-' + varientId];
  }

  updateAddedItem() {
    this.localStorageService.setSuperCart(environment.localStorageKeys.superCart, this.deliveryProducts.value);
  }
}
