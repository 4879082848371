import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../interface';
import { SocialShareService } from './services/social-share.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent implements OnInit {
  @Input() product = {} as Product;
  constructor(
    private socialShareService: SocialShareService,
    public modalController: ModalController,
  ) { }

  ngOnInit() { }

  share(media: string) {
    if (media === 'instagram') {
      this.socialShareService.shareInstagram(this.product);
    }
    if (media === 'whatsApp') {
      this.socialShareService.shareWhatsapp(this.product);
    }
    if (media === 'facebook') {
      this.socialShareService.shareFacebook(this.product);
    }

    this.modalController.dismiss({
      dismissed: true
    });
  }

}
