import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../interface';
import { PRODUCT_LIST } from '../../routes-constants';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'app-suggested-products',
  templateUrl: './suggested-products.component.html',
  styleUrls: ['./suggested-products.component.scss'],
})
export class SuggestedProductsComponent {
  @Input() public title: string | undefined;
  @Input() public products: Product[] | undefined = [];
  @Input() public _id: string | undefined;
  constructor(
    private navigateService: NavigateService
  ) {
    //do something
  }

  productList() {
    // eslint-disable-next-line no-underscore-dangle
    this.navigateService.navigateForward([PRODUCT_LIST], { queryParams: { title: this.title, _id: this._id } });
  }

}
