import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-ok-super-strip',
  templateUrl: './ok-super-strip.component.html',
  styleUrls: ['./ok-super-strip.component.scss'],
})
export class OkSuperStripComponent implements OnInit{
  superSaving: any = {
    orderCount: 0,
    savingAmount: 0
  };
  showDetails = true;
  constructor(
    public dataService: DataService
  ) {
  };

  ngOnInit(){
    if(!this.dataService.userProfile.okSuper || !this.dataService.userProfile.okSuper?.isActive){
      this.getSuperSaving();
    }
  }

  getSuperSaving(){
    this.dataService.getSuperSaving().subscribe((success: any)=>{
      if(success.data?.superDisplay){
        this.superSaving = success.data.superDisplay;
      }
    });
   }

   toggleDetails() {
    this.showDetails = !this.showDetails;
 }

}
