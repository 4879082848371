import { Component, Input } from '@angular/core';
import { CategoryService } from './services/category.service';
import { ComponentType } from '../../shared.enum';
import { NavigateService } from '../../services/navigate.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent {
  @Input() public title = '';
  @Input() public whichComponentType: number | undefined;
  componentType = ComponentType;
  categorySlideOpts: SwiperOptions = {
    direction: 'vertical',
    slidesPerView: 5.1,
    freeMode:false,
    autoplay: false,
    height: 560,
    updateOnWindowResize: true,
    slidesOffsetBefore: 8,
    grabCursor: false,
    loop: false,
    mousewheel: true,
    lazy: true,
    zoom: true,
    effect:'slide',
    spaceBetween: 0,
  };
  categoryDesktopSlideOpts: SwiperOptions = {
    slidesPerView: 5,
    freeMode:false,
    autoplay: false,
    height: 300,
    direction: 'vertical',
    updateOnWindowResize: true,
    slidesOffsetBefore: 0,
    grabCursor: false,
    loop: false,
    mousewheel: true,
    lazy: true,
    zoom: true,
    effect:'fade',
    spaceBetween: 0,
  };
  categorySlideHome = {
    slidesPerView: 3.6,
    slidesOffsetAfter: 30,
    slidesOffsetBefore: 29,
    spaceBetween: 16,
    initialSlide: 0,
  };
  categorySlideDesktopHome={
    slidesPerView: 6,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    spaceBetween: 0,
    initialSlide: 0,
  };
  constructor(
    public categoryService: CategoryService,
    private navigateService: NavigateService,
  ) {
  }
  goToCategory(cat: string) {
    if (this.whichComponentType === this.componentType.Home) {
      this.navigateService.navigateByUrl(`/shop`);
    }
     if(this.categoryService.selectedCategory.value !== cat){
      this.categoryService.selectedCategory.next(cat);
     }
  }

}
