import { Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Utilities } from '../classes/utilities';
import { LoggingService } from './logging.services';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { noop } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class EventService {
    constructor(
        private appsflyer: Appsflyer,
        private firebaseX: FirebaseX,
        private loggingService: LoggingService,
        private cleverTap: CleverTap
        ) { }

    public eventsTrack(eventName: string, eventValues: any) {
        if(Utilities.isApp()){
            this.loggingService.logMessage(eventName,eventValues);
            this.appsflyer.logEvent(eventName, eventValues);
            this.firebaseX.logEvent(eventName,eventValues);
            this.cleverTap.recordEventWithNameAndProps(eventName, eventValues).catch(noop);
        }
    }

}
