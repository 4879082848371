import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { Network } from '@ionic-native/network/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AllInOneSDK } from '@ionic-native/all-in-one-sdk/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SwiperModule } from 'swiper/angular';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { DatePipe } from '@angular/common';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import * as StackTrace from 'stacktrace-js';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { initializeApp } from 'firebase/app';
import { Utilities } from './shared/classes/utilities';
import { environment } from 'src/environments/environment';
import { noop } from 'rxjs';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';



if (!Utilities.isApp()) {
  initializeApp(environment.firebaseConfig);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    SwiperModule
  ],
  providers: [
    Network,
    Geolocation,
    Diagnostic,
    LocationAccuracy,
    AndroidPermissions,
    SocialSharing,
    AppVersion,
    Clipboard,
    InAppBrowser,
    HTTP,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AllInOneSDK,
    StatusBar,
    Appsflyer,
    DatePipe,
    Device,
    FirebaseX,
    File,
    FileOpener,
    FileTransfer,
    CleverTap
    ],
  bootstrap: [AppComponent],
})
export class AppModule extends ErrorHandler {
  constructor(private firebaseX: FirebaseX) {
    super();
}
handleError(error: any) {
  super.handleError(error);
  try {
      if (Utilities.isApp()) {
          StackTrace.fromError(error.originalError || error).then((stackFrames: any) => {
              this.firebaseX.logError(error.toString(), stackFrames).catch(noop);
          }).catch(() => {
              this.firebaseX.logError(error.toString()).catch(noop);
          });
      }
  } catch (e) {
      noop();
  }
}
}
