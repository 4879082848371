/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { catchError, take, tap } from 'rxjs/operators';
import { KeyValues, RequestOptions } from '../interface';
import { environment } from 'src/environments/environment';
import { from, Observable, of, throwError } from 'rxjs';
import { X_ACCESS_TOKEN } from '../constant';
import { Utilities } from '../classes/utilities';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './logging.services';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    public http: HTTP,
    private authService: AuthService,
    private loggingService: LoggingService,
    private platform: Platform,
  ) {
    this.platform.ready().then(() => {
      this.http.setDataSerializer('json');
    });

  }
  sendRequest(
    url: string,
    options: RequestOptions
  ): Observable<object> {
    this.http.setDataSerializer('json');
    if (url === 'api/order/upload/review-files') {
      this.http.setDataSerializer('multipart');
    }
    url = environment.apiURL + url;
    options.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    let isLoaderPresent = true;
    if (this.authService.authKey) {
      options.headers[X_ACCESS_TOKEN] = this.authService.authKey;
    }
    if (options.params && ('hideLoader' in options.params)) {
      delete options.params.hideLoader;
      isLoaderPresent = false;
    }
    if (options.data && ('hideLoader' in options.data)) {
      delete options.data.hideLoader;
      isLoaderPresent = false;
    }
    // isLoaderPresent && this._loaderService.presentLoader();
    return from(this.http.sendRequest(url, options)).pipe(
      take(1),
      tap((success: HTTPResponse) => {
        success.data = Utilities.parseJSONString(success.data);
        this.loggingService.logMessage(options, 'requestOption');
        this.loggingService.logMessage(url, success.data);
      }), catchError((error: HttpErrorResponse) => this.handleError(error, url, options)
      )
    );
  }
  handleError = (error: HttpErrorResponse, url: string, options: RequestOptions) => {
    const err = {
      error: Utilities.parseJSONString(error.error),
      errorMessage: '',
      errorCode: error.status
    };
    if (error.error) {
      // client-side error
      err.errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      err.errorMessage = `Unknown error!`;
    }
    this.loggingService.logMessage(options, 'requestOption');
    this.loggingService.logMessage(url, err);
    return throwError(err);
  };

  get(
    url: string,
    parameters?: KeyValues
  ): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'get', params: parameters })
    );
  }

  post(
    url: string,
    body: KeyValues
  ): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'post', data: body })
    );
  }

  put(url: string, body: KeyValues,): Observable<object> {
    return from(this.sendRequest(url, { method: 'put', data: body }));
  }

  patch(url: string, body: KeyValues): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'patch', data: body })
    );
  }

  delete(
    url: string,
    parameters?: KeyValues
  ): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'delete', params: parameters })
    );
  }

}
