import { Component, OnInit, Input } from '@angular/core';
import { HomeService } from '../../../home/services/home.service';
import { PageCollection } from '../../interface';
import { ToastService } from '../../services/toast.service';
import { Platform } from '@ionic/angular';
import { SwiperOptions } from 'swiper';
import { Utilities } from '../../classes/utilities';

@Component({
  selector: 'app-page-collection',
  templateUrl: './page-collection.component.html',
  styleUrls: ['./page-collection.component.scss'],
})
export class PageCollectionComponent implements OnInit {
  @Input() locationType = '';
  collections: PageCollection[] = [];
  config: SwiperOptions = {
    slidesPerView: 1.2,
    spaceBetween: 20,
    initialSlide: 0,
    slidesOffsetAfter: 30,
    slidesOffsetBefore: 10,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
    freeMode:true,
  };
  constructor(
    private homeService: HomeService,
    private toast: ToastService,
    private platform: Platform
  ) { }

  ngOnInit() {
    //do something
    this.platform.ready().then(() => {
      this.getCollection();
    });
  }

  getCollection() {
    this.homeService.homeDetail(this.locationType).subscribe((res: any) => {
      if (window.innerWidth < 660){
      this.collections = res.data.pageCollections;
      }
      else{
        this.collections = [res.data.pageCollections[0]];
      }
    }, (err) => {
      this.toast.presentErrorToast(err);
    });
  };

}
