import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';
import { SUBSCRIPTION_DAYS } from '../../shared.enum';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { ToastService } from '../../services/toast.service';
import { NavigateService } from '../../services/navigate.service';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { HeaderConfig } from '../../interface';
import { DataService } from '../../services/data.service';
import { PROFILE_SUBSCRIPTION } from '../../routes-constants';
import { HEADER_CONFIG } from '../../constant';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  @Input() product: any;
  @Input() subscriptionId!: string;
  @ViewChild('content') private content: any;
  defaultVariant: any = {};
  subscription: any;
  subscriptionVariants: any = [];
  startDate: any;
  minDate: any;
  cityCalculate: string | undefined;
  societyId = '';
  selectedButton = '';
  buttonColor = '#FFB52B';
  buttonTextColor = '#FFFFFF';
  public dayTypes = SUBSCRIPTION_DAYS;
  showDays = false;
  selectedDays: any = [];
  selectActiveDayColor = 'medium';
  howMuchDiscountIsApplicable = 0;
  week = [
    {
      day: 'Mon',
      value: '1',
    },
    {
      day: 'Tue',
      value: '2',
    },
    {
      day: 'Wed',
      value: '3',
    },
    {
      day: 'Thu',
      value: '4',
    },
    {
      day: 'Fri',
      value: '5',
    },
    {
      day: 'Sat',
      value: '6',
    },
    {
      day: 'Sun',
      value: '0',
    },
  ];
  selected = 1;
  subscriptionQuantity = 1;
  previousHeader: HeaderConfig = HEADER_CONFIG.noHeader;
  previousFooter = false;
  subscriptionForm = this.fb.group({
    startDate: [
      moment(new Date())
        .add(this.dataService.firebaseMasterData.startDateOffSet, 'days')
        .format('YYYY-MM-DD'),
      Validators.required,
    ],
    quantity: [1, Validators.required],
    planType: ['', Validators.required],
  });

  constructor(
    private modalCtrl: ModalController,
    public fb: FormBuilder,
    private profileService: ProfileService,
    private toastService: ToastService,
    private navigateService: NavigateService,
    public activeRoute: ActivatedRoute,
    public dataService: DataService,
    private router: Router
  ) {
    this.subscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd && event.url === '/add-address') {
        (document.querySelector('.subscription') as HTMLElement).style.display =
          'none';
      } else {
        (document.querySelector('.subscription') as HTMLElement).style.display =
          'contents';
      }
    });
  }

  ngOnInit() {
    if (this.dataService.userProfile.isSuper) {
      this.howMuchDiscountIsApplicable = this.dataService.userProfile.okSuper?.plan?.discountPercentage || 0;
    } else {
      this.howMuchDiscountIsApplicable =
        this.dataService?.firebaseMasterData?.subscriptionDiscount;
    }
  }

  ionViewWillEnter() {
    this.previousHeader = this.navigateService.header;
    this.previousFooter = this.navigateService.footer;
    this.navigateService.footer = false;
    let offsetDayValue = this.dataService.firebaseMasterData.startDateOffSet;
    if (this.product?.isOutOfStock) {
      offsetDayValue++;
    }
    this.startDate = moment(new Date())
      .add(offsetDayValue, 'days')
      .format('YYYY-MM-DD');
    this.minDate = moment(new Date())
      .add(offsetDayValue, 'days')
      .format('YYYY-MM-DD');
    this.subscriptionForm.get('startDate')?.setValue(this.startDate);

    if (this.subscriptionId) {
      this.navigateService.header = HEADER_CONFIG.editSubscription;
      this.getActiveSubscriptionDetails(this.subscriptionId);
    } else {
      this.navigateService.header = HEADER_CONFIG.addSubscription;
      this.defaultVariant = this.product?.variants.find(
        (el: any) => el.feature
      );
      this.subscriptionVariants = this.product?.variants;
    }
  }
  ionViewWillLeave(){
    this.navigateService.header = this.previousHeader;
    this.navigateService.footer = this.previousFooter;
  }
  getActiveSubscriptionDetails(id: string) {
    this.profileService.getSubscriptionDetail(id).subscribe((res: any) => {
      const response = res.data.subscriptions;
      this.product = response.product;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < response.variants.length; i++) {
        response.variants.forEach((item: any) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item._id === response.variantId) {
            this.defaultVariant = item;
          }
        });
        this.subscriptionVariants = response?.variants;
      }
      this.subscriptionForm.get('startDate')?.setValue(response.startDate);
      this.startDate = moment(response.startDate).format('LL');
      this.minDate = moment(response.startDate).format('YYYY-MM-DD');
      this.subscriptionQuantity = response.quantity;
      this.subscriptionForm.get('planType')?.setValue(response.planType);
      this.selectedDays = response.deliveryDays;
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  increaseCart() {
    this.subscriptionQuantity++;
  }

  decreaseCart() {
    this.subscriptionQuantity--;
  }

  getActiveDays(day: any) {
    if (this.selectedDays.includes(day.value)) {
      this.selectedDays = this.selectedDays.filter((e: any) => e !== day.value);
    } else {
      this.selectedDays.push(day.value);
    }
  }

  getActiveDayColor(item: any) {
    if (this.selectedDays.includes(item.value)) {
      return 'warning';
    } else {
      return 'medium';
    }
  }

  detail(type: number) {
    if (this.selected === type) {
      this.selected = 1;
    } else {
      this.selected = type;
    }
  }

  getButtonColor(button: string) {
    if (this.selectedButton === button) {
      return this.buttonColor;
    } else {
      return;
    }
  }

  getButtonTextColor(button: string) {
    if (this.selectedButton === button) {
      return this.buttonTextColor;
    } else {
      return;
    }
  }

  selectButton(button: any) {
    this.content.scrollToBottom(100);
    this.selectedButton = button;
    this.subscriptionForm.get('planType')?.setValue(button);
    if (button === SUBSCRIPTION_DAYS.WEEKDAY) {
      this.showDays = true;
      this.selectedDays = ['1', '2', '3', '4', '5'];
    } else if (button === SUBSCRIPTION_DAYS.WEEKEND) {
      this.showDays = true;
      this.selectedDays = ['6', '0'];
    } else {
      this.showDays = false;
      this.selectedDays = [];
    }
  }

  onsubmitForm() {
    const productSubscriptionForm = this.subscriptionForm.value;
    const prodDelDate = new Date(this.startDate);
    // eslint-disable-next-line no-underscore-dangle
    productSubscriptionForm.productId = this.product._id;
    productSubscriptionForm.quantity = this.subscriptionQuantity;
    // eslint-disable-next-line no-underscore-dangle
    productSubscriptionForm.variantId = this.defaultVariant._id;
    productSubscriptionForm.startDate =
      moment(prodDelDate).format('YYYY-MM-DD');
    productSubscriptionForm.deliveryDays = this.selectedDays;
    if (this.subscriptionId) {
      productSubscriptionForm.subscriptionId = this.subscriptionId;

      this.profileService.updateSubscription(productSubscriptionForm).subscribe(
        (res: any) => {
          this.dismiss();
            this.toastService.presentToast(res.data.message);
          this.navigateService.navigateForward([PROFILE_SUBSCRIPTION]);
        },
        (err) => {
          this.toastService.presentErrorToast(err);
        }
      );
    } else {
      this.profileService.createSubscription(productSubscriptionForm).subscribe(
        (res: any) => {
            this.toastService.presentToast(res.data.message);
          this.navigateService.navigateForward([PROFILE_SUBSCRIPTION]);
          this.dismiss();
        },
        (err) => {
          this.toastService.presentErrorToast(err);
        }
      );
    }
  }

  formatDate(popoverDateTime: any) {
    return moment(popoverDateTime.value).format('LL');
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
