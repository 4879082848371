/* eslint-disable @typescript-eslint/naming-convention */
export enum ComponentType {
    Home,
    Shop,
    SuperOk,
    ProductDetails,
    FarmerListing,
    LocationFilter,
    ProductFilter
}

export enum COLORS {
    GREY = '#808080',
    GREEN = '#76FF03',
    YELLOW = '#FFCA28',
    RED = '#DD2C00'
}

export enum ORDER_STATUS {
    Pending = 'Pending',
    Placed = 'Placed',
    Processing = 'Processing',
    Packed = 'Packed',
    Dispatched = 'Dispatched',
    Cancelled = 'Cancelled',
    Delivered = 'Delivered',
    RefundRequested = 'Refund Requested',
    RefundApproved = 'Refund Approved',
    NotPlaced = 'Not Placed',
    PaymentReceived = 'Payment Received',
    RefundInitiated = 'Refund Initiated',
    RefundProcessed = 'Refund Processed'
}

export enum ORDER_TYPE {
    ALACARTE = 'ALACARTE',
    SUPER = 'SUPER',
    ORDER = 'ORDER'
}

export enum MODAL_ACTIONS {
    cancel = 'cancel',
    delete = 'delete'
}

export enum SUBSCRIPTION_DAYS {
    DAILY = 'DAILY',
    ALTERNATE = 'ALTERNATE',
    WEEKDAY = 'WEEKDAY',
    WEEKEND = 'WEEKEND'
}

export enum SUBSCRIPTION_SECTION {
    ACTIVE,
    EXPLORE
}

export enum PAYMENT_OPTION {
    SODEXO = 'SODEXO',
    RAZORPAY = 'RAZORPAY'
}

export enum PAYMENT_SCREEN {
    WALLET_RECHARGE = 'walletRecharge',
    SUPER_NEW_BUY = 'superNewBuy',
    SUPER_RENEW_BUY = 'superRenewBuy',
    ORDER_RECHARGE = 'orderRecharge'
}

export enum ADD_ADDRESS_SECTIONS {
    LOCATION,
    ADDRESS_DETAILS
}

