import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Utilities } from '../../classes/utilities';
import { HEADER_CONFIG } from '../../constant';
import { DataService } from '../../services/data.service';
import { NavigateService } from 'src/app/shared/services/navigate.service';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-payment-save-card',
  templateUrl: './payment-save-card.component.html',
  styleUrls: ['./payment-save-card.component.scss'],
})
export class PaymentSaveCardComponent {
  @Input() carList: any;
  constructor(
    public modalController: ModalController,
    public dataService: DataService,
    private navigateService: NavigateService,
    private alertController: AlertController,
    private http: HttpService
  ) {
  };

  ionViewWillEnter() {
    this.navigateService.header = HEADER_CONFIG.saveCard;
    Utilities.addClassNameByTagName('body', 'hide-footer');
   }
   ionViewWillLeave() {
    this.navigateService.header = HEADER_CONFIG.addMoney;
     Utilities.removeClassNameByTagName('body', 'hide-footer');
   }

   dismiss(sourceId = 'new_card') {
    this.modalController.dismiss({
      sourceId
    });
  }
  async deleteCard(sourceId = '') {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to delete this card?',
      cssClass: 'delete-card',
      buttons: [
        {
          text: ' Yes, Delete this card',
          role: 'confirm',
          handler: () => {
            this.deleteCardDetails(sourceId);
          },
        },
        {
          text: 'Naah Just Kidding ',
          role: 'cancel',
          handler: () => {
          },
        }
      ],
    });
    await alert.present();
  }

  getSavedCard(){
    this.http.get('api/payment/sodexo/list-card').subscribe(
      (res: any)=>{
        this.carList = res.data.cardList;
      }
    );
  }

  deleteCardDetails(sourceId: string){
    this.http.post('api/payment/sodexo/remove-card', {sourceId}).subscribe(
      (res: any)=>{
        this.getSavedCard();
      }
    );
  }

  getCardBalance(sourceId: string, index: number){
    if(this.carList[index].balance === undefined){
      this.carList[index].balance = 0;
      this.http.post('api/payment/sodexo/balance', {sourceId}).subscribe(
        (res: any)=>{
          this.carList[index].balance = res.data.balance;
        }
      );
    }
  }
}
