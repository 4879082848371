import { Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { environment } from 'src/environments/environment';
import { Utilities } from '../classes/utilities';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    constructor(private firebase: FirebaseX) {
    }

    logMessage(message1: any, message2?: any) {
        if (!environment.production) {
            console.log(message1, message2);
        } else if (Utilities.isApp()) {
            this.firebase.logMessage(JSON.stringify(message1) + (message2 ? JSON.stringify(message2) : ''));
        }
    }
}
