import { Component } from '@angular/core';
import { Utilities } from '../../classes/utilities';

@Component({
  selector: 'app-low-balance-popup',
  templateUrl: './low-balance-popup.html',
  styleUrls: ['./low-balance-popup.scss'],
})

export class LowBalancePopupComponent {
  constructor() {};

  ionViewWillEnter() {
    Utilities.addClassNameByTagName('body', 'hide-footer');
   }

   ionViewWillLeave() {
     Utilities.removeClassNameByTagName('body', 'hide-footer');
   }

}
