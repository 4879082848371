import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Utilities } from '../../classes/utilities';
import { PaymentScreenContent } from '../../interface';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'app-payment-screen',
  templateUrl: './payment-screen.component.html',
  styleUrls: ['./payment-screen.component.scss'],
})
export class PaymentScreenComponent {
  @Input() paymentScreen: PaymentScreenContent = {
    header : '',
    img: '',
    subHeader: '',
    redirect: '',
    buttonText:''
  };
  constructor(
    private navigateService: NavigateService,
    private modalCtrl: ModalController
  ) {}

  ionViewWillEnter() {
    Utilities.addClassNameByTagName('body', 'hide-footer');
   }
   ionViewWillLeave() {
     Utilities.removeClassNameByTagName('body', 'hide-footer');
   }

  redirectTo(url: string){
    this.dismiss();
    if(url){
      this.navigateService.navigateByUrl(url);
    }
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

}
