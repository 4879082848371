import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { AuthService } from './shared/services/auth.service';
import { CartService } from './cart/services/cart.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Utilities } from 'src/app/shared/classes/utilities';
import { NavigateService } from './shared/services/navigate.service';
import { LocationService } from './shared/services/location.service';
import { ToastService } from './shared/services/toast.service';
import { Location } from '@angular/common';
import { DataService } from './shared/services/data.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { InitializePluginService } from './shared/services/initialize-plugin.service';
import { APPSFLYER } from './shared/constant';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { DeepLinkService } from './shared/services/deep-link.services';
import { SIGNUP_JOURNEY } from './shared/routes-constants';
import { FirebaseService } from './shared/services/firebase.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
declare let navigator: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isLogin = false;
  name = '';
  public appPages = [
    { title: 'Login', url: 'home/login', icon: 'mail' },
    { title: 'SignUp', url: 'home/signup', icon: 'paper-plane' }
  ];
  hideSplash = Utilities.isApp() ? false: true;
  counterForAppExit = 0;
  deepLinkData: any;
  isApp = !this.platform.is('desktop');
  constructor(
    private authService: AuthService,
    private cartService: CartService,
    private statusBar: StatusBar,
    private locationService: LocationService,
    private platform: Platform,
    public navigateService: NavigateService,
    private toastService: ToastService,
    private location: Location,
    private dataService: DataService,
    private device: Device,
    private initializePluginService: InitializePluginService,
    private appsflyer: Appsflyer,
    private deepLinkService: DeepLinkService,
    private firebaseService: FirebaseService,
    private modalCtrl: ModalController,
    private router: Router
  ) {
   }


  ngOnInit(): void {
    this.platform.ready().then(() => {
      if (Utilities.isApp()) {
         if (!this.authService.isLoggedIn.value) {
        this.navigateService.navigateByUrl(SIGNUP_JOURNEY);
      }
        // let status bar overlay webview
        this.platform.backButton.subscribe(() => {
          if (this.location.path() === '') {
            const appName = 'app';
            if (this.counterForAppExit === 1) {
              navigator[appName].exitApp();
            } else {
              this.modalCtrl.getTop().then(modal => {
                if(!modal){
                this.counterForAppExit++;
                this.toastService.presentToast('Press again to exit');
                setTimeout(() => {
                  this.counterForAppExit = 0;
                }, 2100);
                }
              });
            }
          }
        });
        this.registerAppsFlyerAttribution();
      }else{
        this.router.events.subscribe((event: any) => {
          if (event instanceof NavigationStart) {
          this.modalCtrl.getTop().then(modal => {
            if(modal){
              modal.dismiss();
            }
          });
        }
        });
      }
      this.locationService.updateCity('Gurugram');
      setTimeout(() => {
        this.hideSplash = true;
          this.dataService.uuid = this.device.uuid;
          this.dataService.knockKnock.subscribe((subscribed) => {
            if (subscribed) {
              this.initializePluginService.doInitializePlugin();
            } else {
              this.firebaseService.registerForPushNotification();
            }
          });
        this.dataService.getUserProfile(undefined);
      }, Utilities.isApp() ? 3000 : 0);
    });

  }
  registerAppsFlyerAttribution = () => {
    this.appsflyer.registerOnAppOpenAttribution().then(
      response => {
        const deepLinkData = Utilities.parseJSONString(response);
        this.deepLinkData = deepLinkData;
        if (deepLinkData.type === APPSFLYER.onAppOpenAttribution) {
          deepLinkData.data.deeplink = deepLinkData.data.af_dp || deepLinkData.data.af_deeplink || null;
          this.deepLinkService.deepLinkHandler(deepLinkData.data);
        }
      }).catch((res)=>{
      });
  };

  logOut() {
    this.cartService.emptyLocalCart();
    this.authService.logOut();
  }
}
