import { Component, OnInit, Input } from '@angular/core';
import { Vendor } from '../../../organic-kitchen/super-ok.interface';
@Component({
  selector: 'app-product-farm-slide',
  templateUrl: './product-farm-slide.component.html',
  styleUrls: ['./product-farm-slide.component.scss'],
})
export class ProductFarmSlideComponent implements OnInit {
  @Input() public farms: Vendor[] = [];
  title = 'Know your Farm';
  recommendedProducts: any = {
    slidesPerView: 2.5,
    spaceBetween: 16,
    initialSlide: 0,
    slidesOffsetAfter: 16,
    slidesOffsetBefore: 16,
    direction: 'horizontal',
    freeMode: true,
  };
  constructor() {}

  ngOnInit() {}
}
