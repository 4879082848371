import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { CartProduct } from '../../shared/interface';
import { HttpService } from '../../shared/services/http.service';
import { AuthService } from '../../shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { NavigateService } from '../../shared/services/navigate.service';
import { SuperCartService } from 'src/app/organic-kitchen/super-ok-plan/services/super-cart.service';
import { DataService } from 'src/app/shared/services/data.service';
import { Utilities } from 'src/app/shared/classes/utilities';
import { environment } from 'src/environments/environment';
import { ModalService } from 'src/app/shared/services/modal.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cartProducts = new BehaviorSubject<CartProduct[]>([]);
  cartProductVariant = new BehaviorSubject<any>({});
  selectedVendor: any = {};
  public cartQuantity = 0;
  constructor(
    private http: HttpService,
    private authService: AuthService,
    private superCartService: SuperCartService,
    private platform: Platform,
    public navigationService: NavigateService,
    private toastService: ToastService,
    private dataService: DataService,
    private modalService: ModalService
  ) {
    this.platform.ready().then(() => {
      this.loadCartProduct();
    });
    this.cartProducts.subscribe((res: any) => {
      if (res.length) {
        localStorage.setItem('cart', JSON.stringify(res));
        this.cartQuantity = 0;
        let cpvObject = res.reduce((cpv: any, pv: any) => {
          cpv[`${pv.productId}-${pv.variantId}`] = pv.quantity;
          this.cartQuantity += pv.quantity;
          return cpv;
        }, {});
        cpvObject = cpvObject ? cpvObject : {};
        this.cartProductVariant.next(cpvObject);
      } else {
        this.cartQuantity = 0;
        this.cartProductVariant.next({});
      }
    }, (err: any) => {
      this.toastService.presentErrorToast(err);
    });
  }

  loadCartProduct() {
    if (this.authService.isLoggedIn.value) {
      this.getCartProduct().subscribe((res: any) => {
        if (res.data != null) {
          this.cartProducts.next(res.data.cartProducts);
        }
      }, (err: any) => {
        this.toastService.presentErrorToast(err);
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const cart = JSON.parse(localStorage.getItem('cart')!);
      this.cartProducts.next(cart || []);
    }
  }

  getLocalProductDetails(data: any) {
    return this.http.post('api/localCartProduct', data);
  }

  getLocalCalculatedPrice(data: any) {
    return this.http.post('api/localCartProductPrice', data);
  }

  getServerCalculatedPrice(data: any) {
    return this.http.post('api/cartProductPrice', data);
  }

  getCartProduct() {
    return this.http.get('api/cartProduct');
  }

  mergeCart(cartProducts: any) {
    return this.http.put('api/mergeCart', cartProducts);
  }

  addProductToCart(cartProduct: any) {
    return this.http.post('api/cart', { product: cartProduct });
  }

  updateProductToCart(cartProduct: CartProduct) {
    return this.http.put('api/cart', { product: cartProduct });
  }
  addToCart(product: any) {
    if (this.authService.isLoggedIn.value) {
      if (this.superCartService.isEditDelivery) {
        const cart: any = this.superCartService.deliveryProducts.value;
        let variant = null;
        for (const variantItem of product.productId.variants) {
          // eslint-disable-next-line no-underscore-dangle
          if (product.variantId === variantItem._id) {
            variant = variantItem;
          }
        }
        cart.push({
          // eslint-disable-next-line no-underscore-dangle
          productId: product.productId._id,
          quantity: 1,
          variantId: product.variantId,
          variantObj: variant,
          productInfo: {
            photo: product.productId.photo[0],
            name: product.productId.name,
            nativeName: product.productId.nativeName,
          },
          version: environment.appVersion
        });
        this.superCartService.deliveryProducts.next(cart);
      } else {
        // eslint-disable-next-line no-underscore-dangle
        const addProduct: any = { productId: product.productId._id, variantId: product.variantId,
           quantity: product.quantity,  version: environment.appVersion };
            // eslint-disable-next-line no-underscore-dangle
          if(this.selectedVendor && this.selectedVendor._id){
            // eslint-disable-next-line no-underscore-dangle
            addProduct.sourceFarmerId = this.selectedVendor._id;
            addProduct.sourceFarmer = this.selectedVendor.name;
          }
        this.addProductToCart(addProduct).subscribe((res: any) => {
          if(product.productId && product.productId.securityAmount){
            this.modalService.securityMessage();
          }
          this.loadCartProduct();
        }, (err: any) => {
          if(err && err.error && err.error.message === 'Insufficient wallet balance for add to cart action'){
            this.modalService.lowBalancePopup();
          }else{
          this.toastService.presentErrorToast(err);
          }
        });
      }
    } else {
      const cart: any = this.cartProducts.value;
      cart.push({
        // eslint-disable-next-line no-underscore-dangle
        productId: product.productId._id,
        variantId: product.variantId,
        quantity: 1
      });
      this.cartProducts.next(cart);
    }

  }

  increase(product: any) {
    if (this.authService.isLoggedIn.value) {
      if (this.superCartService.isEditDelivery) {
        let cart = this.superCartService.deliveryProducts.value;
        cart = cart.map((cp: any) => {
          if (product.productId === cp.productId && product.variantId === cp.variantId) {
            cp.quantity++;
            return cp;
          } else {
            return cp;
          }
        });
        this.superCartService.deliveryProducts.next(cart);
      }
      else {
        product.quantity = this.cartProductQuantity(product.productId, product.variantId) + 1;
        // eslint-disable-next-line no-underscore-dangle
        if(this.selectedVendor && this.selectedVendor._id){
          // eslint-disable-next-line no-underscore-dangle
          product.sourceFarmerId = this.selectedVendor._id;
          product.sourceFarmer = this.selectedVendor.name;
        }
        this.updateProductToCart(product).subscribe((res: any) => {
          this.loadCartProduct();
        }, (err: any) => {
          if(err && err.error && err.error.message === 'Insufficient wallet balance for add to cart action'){
            this.modalService.lowBalancePopup();
          }else{
          this.toastService.presentErrorToast(err);
          }
        });
      }
    } else {
      let cart: CartProduct[] = this.cartProducts.value;
      cart = cart.map((cp) => {
        if (product.productId === cp.productId && product.variantId === cp.variantId) {
          cp.quantity++;
          return cp;
        } else {
          return cp;
        }
      });
      this.cartProducts.next(cart);
    }
  }

  decrease(product: any) {
    if (this.authService.isLoggedIn.value) {
      if (this.superCartService.isEditDelivery) {
        let cart = this.superCartService.deliveryProducts.value;
        cart = cart.map((cp: CartProduct) => {
          if (product.productId === cp.productId && product.variantId === cp.variantId) {
            cp.quantity--;
            return cp;
          } else {
            return cp;
          }
        });
        cart = cart.filter((el: any) => el.quantity === 0 ? false : true);
        this.superCartService.deliveryProducts.next(cart);
      } else {
        product.quantity = this.cartProductQuantity(product.productId, product.variantId) - 1;
         // eslint-disable-next-line no-underscore-dangle
         if(this.selectedVendor && this.selectedVendor._id){
          // eslint-disable-next-line no-underscore-dangle
          product.sourceFarmerId = this.selectedVendor._id;
          product.sourceFarmer = this.selectedVendor.name;
        }
        this.updateProductToCart(product).subscribe((res: any) => {
          this.loadCartProduct();
        }, (err: any) => {
          this.toastService.presentErrorToast(err);
        });
      }
    } else {
      let cart: CartProduct[] = this.cartProducts.value;
      cart = cart.map((cp: CartProduct) => {
        if (product.productId === cp.productId && product.variantId === cp.variantId) {
          cp.quantity--;
          return cp;
        } else {
          return cp;
        }
      });
      cart = cart.filter((el) => el.quantity === 0 ? false : true);
      this.cartProducts.next(cart);
    }
  }

  cart() {
    return this.cartProducts.value;
  }

  public cartProductQuantity(productId: string, varientId: string) {
    return this.cartProductVariant.value[productId + '-' + varientId];
  }

  syncCart() {
    if (this.cartQuantity <= 0) {
      this.loadCartProduct();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.mergeCart({ localCartProducts: JSON.parse(localStorage.getItem('cart')!) }).subscribe((res: any) => {
      this.loadCartProduct();
      this.emptyLocalCart();
    }, (err: any) => {
      // this.toastService.presentErrorToast(err);
    });
  }

  emptyLocalCart(logOut = false) {
    localStorage.setItem('cart', JSON.stringify([]));
    this.cartProducts.next([]);
    this.cartQuantity = 0;
    if (logOut) {
      this.logOut();
    }
  }

  logOut() {
    if (Utilities.isApp()) {
      this.http.post('api/auth/logout', { deviceId: this.dataService.uuid }).subscribe();
    }
  }
}
