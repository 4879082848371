import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EmptyScreen } from '../../interface';
import { NavigateService } from '../../services/navigate.service';
import {SUBSCRIPTION_SECTION} from '../../shared.enum';

@Component({
  selector: 'app-emptyscreen',
  templateUrl: './emptyscreen.component.html',
  styleUrls: ['./emptyscreen.component.scss'],
})
export class EmptyscreenComponent implements OnInit {
  @Input() page!: string;

  index!: number;

  screenContent: Array<EmptyScreen> = [
    {
      page: 'cart',
      header: 'Shopping Cart',
      img: '../../../assets/images/empty-screen/cart.svg',
      displayHeading: 'Your cart is empty',
      displayParagraph: 'Lets get you started',
      buttonText: 'Explore Now',
      buttonRoute: '/shop',
    },
    {
      page: 'order',
      header: 'My Orders',
      img: '../../../assets/images/empty-screen/order.svg',
      displayHeading: 'Hey, we are still waiting for your order',
      displayParagraph: 'Place an order with us now!',
      buttonText: 'Explore Now',
      buttonRoute: '/shop',
    },
    {
      page: 'noDelivery',
      header: 'My Orders',
      img: '../../../assets/images/empty-screen/order.svg',
      displayHeading: 'Hey, we are still waiting for your order',
      displayParagraph: 'Place an order with us now!',
      buttonText: 'Explore More',
      buttonRoute: '/shop',
    },
    {
      page: 'address',
      header: 'Addresses',
      img: '../../../assets/images/empty-screen/address.svg',
      displayHeading: 'There are no saved addresses',
      displayParagraph: 'Start by adding an address',
      buttonText: 'Add Address',
      buttonRoute: '/add-address',
    },
    {
      page: 'orderPlaced',
      header: 'Addresses',
      img: '../../../assets/images/empty-screen/address.svg',
      displayHeading: 'There are no saved addresses',
      displayParagraph: 'Start by adding an address',
      buttonText: 'See Orders',
      buttonRoute: '/order',
    },
    {
      page: 'activeSubscriptions',
      header: 'My Subscriptions',
      img: '../../../assets/images/empty-screen/order.svg',
      displayHeading: `Hey, you don't have any active subscriptions`,
      displayParagraph: 'Let’s get you started',
      buttonText: 'Explore More',
      buttonRoute: `/profile/my-subscriptions/${SUBSCRIPTION_SECTION.EXPLORE}`,
    },
  ];

  constructor(private navigateService: NavigateService) {}

  ngOnInit() {
    this.checkIndex();
  }

  checkIndex() {
    this.screenContent.forEach((content, i) => {
      if (content.page === this.page) {
        this.index = i;
      }
    });
  }

  route() {
    this.navigateService.navigateByUrl(
      this.screenContent[this.index].buttonRoute
    );
  }
}
