import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { HttpService } from '../../../shared/services/http.service';
import { Address } from '../../profile-interface';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  userId: any;
  addressList: Address[] = [];
  constructor(
    private http: HttpService,
    private toastService: ToastService
    ) {
  }

  addAddress(data: any) {
    return this.http.post('api/address', data);
  }

  addresses() {
   this.http.get('api/address').subscribe((res: any) => {
    this.addressList = res.data.data;
    }, (err) => {
      this.toastService.presentErrorToast(err);
    });
  };

  removeAddress(id: string) {
    return this.http.delete(`api/address/${id}`);
  };

  getAddressDetails(id: string) {
    return this.http.get(`api/address/${id}`);
  }

  editAddress(data: any) {
    return this.http.put('api/address', data);
  }
}
