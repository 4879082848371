import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth-guard.service';
import { LoginGuard } from './shared/services/login.guard';
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'farm-details',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'ok-super',
    loadChildren: () =>
      import('./organic-kitchen/organic-kitchen.module').then(
        (m) => m.OrganicKitchenPageModule
      ),
  },
  {
    path: 'buy-ok',
    loadChildren: () =>
      import('./buy-ok/buy-ok.module').then((m) => m.BuyOkPageModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-address',
    loadChildren: () =>
      import('./add-address/add-address.module').then(
        (m) => m.AddAddressPageModule
      ),
  },
  {
    path: 'shop',
    loadChildren: () =>
      import('./shop/shop.module').then((m) => m.ShopPageModule),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./cart/cart.module').then((m) => m.CartPageModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'product-list',
    loadChildren: () =>
      import('./product-list/product-list.module').then(
        (m) => m.ProductListPageModule
      ),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./register/terms/terms-routing.module').then(
        (m) => m.TermsPageRoutingModule
      ),
  },
  {
    path: 'farmer-listing',
    loadChildren: () =>
      import('./farmer-listing/farmer-listing.module').then(
        (m) => m.FarmerListingPageModule
      ),
  },
  {
    path: 'campaign-super-offline',
    loadChildren: () =>
      import('./campaign-super-offline/campaign-super-offline.module').then(
        (m) => m.CampaignSuperOfflinePageModule
      ),
  },
  {
    path: 'success-super',
    loadChildren: () =>
      import(
        './campaign-super-offline/success-super/success-super-routing.module'
      ).then((m) => m.SuccessSuperPageRoutingModule),
  },
  {
    path: 'cancel-generic',
    loadChildren: () =>
      import('./transaction-screens/cancel-generic/cancel-generic.module').then(
        (m) => m.CancelGenericPageModule
      ),
  },
  {
    path: 'subscription-status',
    loadChildren: () =>
      import(
        './transaction-screens/subscription-status/subscription-status.module'
      ).then((m) => m.SubscriptionStatusPageModule),
  },
  {
    path: 'payment-status',
    loadChildren: () =>
      import('./payment-screen/payment-screen.module').then(
        (m) => m.PaymentScreenPageModule
      ),
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./terms-conditions/terms-conditions.module').then
        (m => m.TermsConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then
        (m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then
        (m => m.AboutUsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then
        (m => m.ContactUsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./faq/faq.module').then
        (m => m.FaqPageModule)
  },
  {
    path: 'franchise',
    loadChildren: () =>
      import('./franchise/franchise.module').then
        (m => m.FranchisePageModule)
  },
  {
    path: 'journey',
    loadChildren: () =>
      import('./journey/journey.module').then
        (m => m.JourneyPageModule)
  },
  {
    path: 'our-team',
    loadChildren: () =>
      import('./our-team/our-team.module').then
        (m => m.OurTeamPageModule)
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./careers/careers.module').then
        (m => m.CareersPageModule)
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./blog/blog.module').then
        (m => m.BlogPageModule)
  },

  {
    path: 'recipe',
    loadChildren: () =>
      import('./recipe/recipe.module').then
        (m => m.RecipePageModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
