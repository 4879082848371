import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) {return [];}
    if(!searchText) {return items;}
      searchText = searchText.toLowerCase();
      return items.filter( (it)=>{
        if(it.name && it.name.toLowerCase().includes(searchText)){
          return true;
        };
        if(it.products && it.products.join(',').toLowerCase().includes(searchText)){
           return true;
        };
        if(it && typeof(it) === 'string' && it.toLowerCase().includes(searchText)){
          return true;
        }
        return false;
      });
   }
};
