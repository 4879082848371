import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  imageUpload(data: any) {
    return this.http.post('api/order/upload/review-files', data);
  }

  browserUpload(data: any) {
    return this.httpClient.post(
      'https://api.okindia.com/api/order/upload/review-files',
      data,
      {
        headers: new HttpHeaders().set(
          'x-access-token',
          this.authService.authKey || []
        ),
        // .set('Content-Type', 'multipart/form-data'),
      }
    );
  }

  // submit rating
  submitRating(data: any) {
    return this.http.post('api/order/review', data);
  }

  // delete Order
  deleteOrder(data: any) {
    return this.http.post('api/order/cancel', data);
  }

  // create subscription
  createSubscription(data: any) {
    return this.http.post('api/subscription', data);
  }

  // update subscription
  updateSubscription(data: any) {
    return this.http.put('api/subscription', data);
  }

  // get active subscription list
  getActiveSubscriptions() {
    return this.http.get(`api/subscription-list`);
  }

  // get single active subscription detail
  getSubscriptionDetail(id: string) {
    return this.http.get(`api/subscription/${id}`);
  }

  // get single active subscription detail
  getExploreSection() {
    return this.http.get(`api/explore-subscription/`);
  }

  // delete active subscription
  deleteActiveSubscription(id: string) {
    return this.http.delete(`api/subscription/${id}`);
  }

  pauseSubscription(data: any) {
    return this.http.post(`api/subscription/status`, data);
  }
}
