import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpService } from '../../shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpService, private platform: Platform) { }

  homeDetail(page: any) {
    return this.http.get('api/pageCollection', { locationType: page });
  }

  banner() {
    return this.http.get(`api/banner${this.platform.is('desktop') ? '?source=browser': ''}`);
  }
  checkAppVersion(platform: string,version: string){
    const config ={
      version,
      platform
    };

    return this.http.post('api/verifyApplicationVersion',config);
  }

}
