import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-product-day',
  templateUrl: './product-day.component.html',
  styleUrls: ['./product-day.component.scss'],
})
export class ProductDayComponent implements OnInit {
  @Input() public products: any;
  productday: any = {
    slidesPerView: 1,
    spaceBetween: 5,
    initialSlide: 0,
    slidesOffsetAfter: 16,
    slidesOffsetBefore: 16,
    direction: 'horizontal',
    freeMode: true,
  };
  constructor() {
    //do something
  }
  ngOnInit() {
    //do something
  }
}
