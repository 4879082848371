import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SelectQuantityComponent } from '../select-quantity/select-quantity.component';
import { Product } from './product-detail-interface';
import { CartService } from '../../../cart/services/cart.service';
import { NavigateService } from '../../services/navigate.service';
import { ToastService } from '../../services/toast.service';
import { FarmService } from '../farmer-detail/farm.service';
import { EventService } from '../../services/events.service';
import { EVENT_ADD_TO_CART } from '../../events-constants';
import { ProductService } from '../../../product-list/services/product.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { HEADER_CONFIG } from '../../constant';
import { ModalService } from '../../services/modal.service';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper';
import { HeaderConfig } from '../../interface';
import { AddressService } from 'src/app/profile/edit-address/services/address.service';
import { FaqComponent } from 'src/app/buy-ok/faq/faq.component';
import { ComponentType } from '../../shared.enum';
import { Vendor } from '../farmer-detail/farm.interface';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent {
  @Input() productId: any;
  title = 'Select Source';
  componentType = ComponentType.ProductDetails;
  vendors: Vendor[] = [];
  i: number | undefined;
  selectedItem: any;
  price = 0;
  discountPrice = 0;
  defaultVariant: any = {};
  weight = '';
  unit = '';
  productDetail = {} as Product;
  suggestedProductTitle = 'More Products in this Category';
  config: any = {
    slidesPerView: 1,
    spaceBetween: 10,
    initialSlide: 0,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    height:300,
    autoplay: {
      delay: 35000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
      bulletClass: `swiper-pagination-bullet`,
    },
    direction: 'horizontal',
    freeMode: true,
  };
  recommendedProducts = {
    slidesPerView: 2,
    initialSlide: 0,
    spaceBetween: 0,
    slidesOffsetAfter:0,
    slidesOffsetBefore: 3,
  };
  boxProducts: any = [];
  boxTitle = 'This Box Contains';
  previousHeader: HeaderConfig = HEADER_CONFIG.noHeader;
  previousFooter = false;
  mapperForVendor: any ={};
  constructor(
    public activeRoute: ActivatedRoute,
    private productService: ProductService,
    private modalCtrl: ModalController,
    public navigateService: NavigateService,
    private toastService: ToastService,
    public cartService: CartService,
    private farmService: FarmService,
    private eventService: EventService,
    public authService: AuthService,
    public dataService: DataService,
    public modalService: ModalService,
    private addressService: AddressService
  ) {}

  ionViewWillEnter() {
    this.previousHeader = this.navigateService.header;
    this.previousFooter = this.navigateService.footer;
    this.navigateService.header = HEADER_CONFIG.productDetail;
    this.navigateService.footer = true;
    this.getProductDetail();
    this.getFarmsDetails();
  }

  ionViewWillLeave() {
    this.navigateService.header = this.previousHeader;
    this.navigateService.footer = this.previousFooter;
    this.dismissModal();
  }

  navigateTo(url: string){
    this.dismissModal();
    this.navigateService.navigateByUrl(url);
  }

  dismissModal(){
    this.cartService.selectedVendor = {};
    this.modalCtrl.dismiss();
  }

  addToCart(productId: any, variantId: string) {
    this.eventService.eventsTrack(EVENT_ADD_TO_CART, { productId });
    if(this.authService.isLoggedIn.value){
      if(this.addressService.addressList.length){
        const dataToSend: any = { productId, variantId, quantity: 1 };
        this.cartService.addToCart(dataToSend);
      }else{
        this.modalService.addAddressModal();
      }
    } else {
      this.modalService.loginAlert();
    }
  }

  increaseCart(productId: string, variantId: string) {
    const dataToSend: any = { productId, variantId, quantity: 1 };
    this.cartService.increase(dataToSend);
  }

  decreaseCart(productId: string, variantId: string) {
    this.cartService.decrease({ productId, variantId, quantity: 1 });
  }

  getProductDetail() {
    this.productService.product(this.productId).subscribe(
      (res: any) => {
        this.productDetail = res.data;
        if (this.productDetail.box_products.length > 1) {
          this.boxProducts = this.productDetail.box_products;
        }
        this.productDetail.variants.forEach((element) => {
          element.price = Math.round(element.price);
          element.discountPrice = Math.round(element.discountPrice);
          if (element.feature) {
            this.defaultVariant = element;
          }
          this.mapperForVendor[element.qualityType] = element;
        });
      },
      (err) => {
        this.toastService.presentErrorToast(err);
      }
    );
  }

  getFarmsDetails() {
    this.farmService.getVendors(this.productId).subscribe(
      (res: any) => {
        this.vendors = res.data.vendors;
        this.cartService.cartProducts.value.forEach((element: any) => {
          if(element.productId === this.productId){
              if(element.sourceFarmerId && element.sourceFarmer){
                this.cartService.selectedVendor = {
                  _id : element.sourceFarmerId,
                  name: element.sourceFarmer
                };
              }
          }
        });
      },
      (err) => {
        this.toastService.presentErrorToast(err);
      }
    );
  }
  async faq() {
    const termModal = await this.modalCtrl.create({
      component: FaqComponent,
      cssClass: 'my-custom-class',
    });
    return await termModal.present();
  }
  farmDetails(vendorId: string) {
    this.modalService.farmerDetail(this.productId, vendorId);
  }

  showDescription(i: number) {
    this.selectedItem = i;
  }

  async selectQuantity(product: any) {
    if (product.variants.length < 2) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: SelectQuantityComponent,
      cssClass: 'quantity-modal',
      breakpoints: [ 0, 0.42, 0.45 ],
      backdropDismiss: true,
      initialBreakpoint: 0.42,
      componentProps: { product },
    });
    return await modal.present();
  }

}
