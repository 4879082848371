import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartService } from '../../../cart/services/cart.service';
import { NavigateService } from '../../services/navigate.service';
import { AddressService } from 'src/app/profile/edit-address/services/address.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  city!: string;
  browser = false;
  constructor(
    private modalController: ModalController,
    public cartService: CartService,
    public navigateService: NavigateService,
    private modalCtrl: ModalController,
    public addressService: AddressService
  ) {
}

  navigateToCart = () => {
    this.modalController
      .getTop()
      .then(topModal => {
        if (topModal) {
          topModal.dismiss().then(() => {
            this.navigateToCart();
          });
        }else{
          this.navigateService.navigateByUrl('/cart');
        }
      })
      .catch(error => {
      });

  };

  backClicked(){
    this.modalCtrl.getTop().then(modal => {
      if(!modal){
      if(this.navigateService.header.navigationUrl){
        this.navigateService.navigateByUrl(this.navigateService.header.navigationUrl);
      }else{
        this.navigateService.navigateBack();
      }
    }else{
      modal.dismiss();
    }
  });
  }
  navigateTo(url: string){
    this.modalCtrl.getTop().then(modal => {
      if(modal){
        modal.dismiss();
      }
        this.navigateService.navigateByUrl(url);
    });
  }
}
