export const HOME = '/';

// login
export const LOGIN_PAGE = '/login';

// signup-journey
export const SIGNUP_JOURNEY = '/signup-journey';

// signup
export const SIGN_UP = '/signup';

// search
export const PRODUCT_SEARCH = '/search';

// shop
export const SHOP = '/shop';
export const SHOP_VEGETABLES = '/shop/Vegetables';

// ok super
export const BUY_SUPER = '/buy-ok';
export const SUPER = 'ok-super';
export const SUPER_HOME = '/ok-super/super-ok-plan';
export const ACTIVE_SUPER_HOME = '/organic-kitchen/super-ok-plan';
export const SUCCESS_EDIT_SUPER = '/ok-super/success-edit-order';
export const EDIT_SUPER_ORDER = '/ok-super/edit-delivery';
export const RENEW_PLAN = '/ok-super/renew-plan';
export const RENEW_PLAN_DETAILS = '/ok-super/renew-plan-details';

// profile
export const PROFILE = '/profile';
export const PROFILE_ORDER = '/profile/my-order';
export const PROFILE_SUBSCRIPTION = '/profile/my-subscriptions';
export const PROFILE_ADD_MONEY = '/profile/add-money';

// address
export const ADD_ADDRESS = '/add-address';

// cancel pop
export const CANCEL_GENERIC = '/cancel-generic';

// order
export const ORDER_DETAIL = '/profile/order-detail';

// product
export const PRODUCT_LIST = '/product-list';
export const PRODUCT_DETAIL = '/product-detail';

export const PRODUCT_DETAIL_PRODUCT_ID = 'product-detail?id=';

// farm-details
export const FARM_DETAILS = '/farm-details';

// subscription
export const SUBSCRIPTION_STATUS = '/subscription-status';
export const ORDER_DETAIL_ORDER_ID = '/profile/order-detail?orderId=';

export const OK_SUPER_PLAN_HISTORY = 'ok-super/add-money';

export const PROFILE_WALLET = 'profile/wallet';

export const PAYMENT_PAGE = '/payment-status';



