import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NavigateService } from '../../services/navigate.service';
import { HOME, PRODUCT_SEARCH, PROFILE, SHOP, SUPER } from '../../routes-constants';
import { WhatsappAlertComponent } from '../whatsapp-alert/whatsapp-alert.component';
import { ModalController } from '@ionic/angular';
import { LoggingService } from '../../services/logging.services';
import { CategoryService } from '../category/services/category.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent{

  routeHome = HOME;
  routeShop = SHOP;
  routeSearch = PRODUCT_SEARCH;
  routeSuper = SUPER;
  routeProfile = PROFILE;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    public navigateService: NavigateService,
    private loggingService: LoggingService,
    public categoryService: CategoryService,
  ) {
  }

  checkUser() {
    if (this.authService.isLoggedIn.value) {
      this.navigateService.navigateByUrl('/profile');
    }
    else {
      this.navigateService.navigateByUrl('/login');
    }
  };
  async whatsAppChat() {
    const chatModal = await this.modalController.create({
      component: WhatsappAlertComponent,
      cssClass: 'chatModal',
    });
    return await chatModal.present();
  }

  dismissAllModal = () => {
    this.modalController
      .getTop()
      .then(topModal => {
        if (topModal) {
          topModal.dismiss().then(() => {
            this.dismissAllModal();
          });
        }
      })
      .catch(error => {
        this.loggingService.logMessage('Error while fetching top modal: ', error);
      });
  };
  goToCategory(cat: string) {
      this.navigateService.navigateByUrl(`/shop`);
     if(this.categoryService.selectedCategory.value !== cat){
      this.categoryService.selectedCategory.next(cat);
     }
  }

}
