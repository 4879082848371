export const EVENT_PURCHASE = 'Purchase';
export const EVENT_SUBSCRIBE_SUPER = 'Subscribe_Super';
export const EVENT_INITIATE_CHECKOUT = 'Initiate_Checkout';
export const EVENT_FAILED_PURCHASE = 'Failed_Purchase';
export const EVENT_ADD_TO_CART = 'Add_To_Cart';
export const EVENT_IN_APP_REGISTRATION = 'In_App_Registration';
export const HOME_PAGE_VIEWED = 'Home_Page_Viewed';
export const SPLASH_SCREEN_VIEWED = 'Splash_Screen_Viewed';
export const LOGIN_PAGE_VIEWED = 'Login_Page_Viewed';
export const TERM_AND_CONDITION_VIEW= 'Terms_And_Condition_View';
export const SIGNUP_SKIP = 'Signup_Skip';
