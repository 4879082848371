import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LOGIN_PAGE } from '../routes-constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    public authService: AuthService
  ) { }
  canActivate(): boolean {

    if (!this.authService.isLoggedIn.value) {
      this.router.navigate([LOGIN_PAGE]);
    }
    return this.authService.isLoggedIn.value;
  }
}
