import { Injectable } from '@angular/core';
import { Utilities } from '../classes/utilities';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { ANDROID, FIREBASE, FIREBASE_KEY, IOS, STORAGE_KEYS } from '../constant';
import { Platform } from '@ionic/angular';
import { LoggingService } from './logging.services';
import { DataService } from './data.service';
import { DeepLinkService } from './deep-link.services';
import { HttpService } from './http.service';
import { RegisterForPushNotificationDataToSend } from '../interface';
import { Observable } from 'rxjs';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    private retry = 1;
    constructor(
        private firebase: FirebaseX,
        private platform: Platform,
        private loggingService: LoggingService,
        private dataService: DataService,
        private deepLinkService: DeepLinkService,
        private httpService: HttpService
    ) { }

    registerForPushNotification() {
        if (Utilities.isApp()) {
            this.requestFirebaseToken();
            this.onNotificationOpen();
            this.fetchRemoteConfig();
        }else{
            this.fetchConfigVersionForWeb();
        }
    }
    fetchRemoteConfig() {
        this.firebase.fetch(FIREBASE.time).then(() => {
            this.activateFetchRemoteConfig();
            this.retry = 1;
        }, (error) => {
            this.loggingService.logMessage('Firebase fetch error: ', error);
            if (++this.retry <= 3) {
                this.fetchRemoteConfig();
            }
        });
    }
    activateFetchRemoteConfig() {
        this.firebase.activateFetched().then(() => {
            this.fetchRemoteValues();
        }, (error) => {
            this.loggingService.logMessage('activateFetched error: ', error);
            // config is already activated occurs on IOS only
            if (this.platform.is(IOS) && error && error.match(FIREBASE.configIsAlreadyActivated)) {
                this.fetchRemoteValues();
            } else if (++this.retry <= 3) {
                this.activateFetchRemoteConfig();
            }
        });
    }

    fetchRemoteValues() {
        this.retry = 1;
        this.getFirebaseConfigVersion(this.retry);
    }

    getFirebaseConfigVersion(retry: number) {
        const firebaseData = localStorage.getItem(STORAGE_KEYS.firebaseConfig);
        let firebaseMasterData: any;
        if (firebaseData) {
            firebaseMasterData = JSON.parse(firebaseData);
        }
        this.firebase.getValue(FIREBASE_KEY.configVersion).then((versionInfo) => {
        if (versionInfo !== firebaseMasterData?.firebaseFetchVersion) {
                const retryCount = 1;
                this.getValues(retryCount);
            } else {
                this.assignDataAndTriggerCalls(firebaseMasterData);
            }
        }, (error) => {
            this.loggingService.logMessage(`Firebase getValue error: `, error);
            if (++retry <= 3) {
                this.getFirebaseConfigVersion(retry);
            }
        });
    }

    getValues(retry: number) {
        this.firebase.getValue(FIREBASE_KEY.firebaseMasterData).then((data) => {
            const firebaseMasterData = JSON.parse(data);
            this.assignDataAndTriggerCalls(firebaseMasterData, true);
        }, (error) => {
            this.loggingService.logMessage(`Firebase getValue error: `, error);
            if (++retry <= 3) {
                this.getValues(retry);
            }
        });
    }

    assignDataAndTriggerCalls(firebaseMasterData: any, updateStorage = false) {
        this.dataService.firebaseMasterData = firebaseMasterData;
        if (updateStorage) {
            localStorage.setItem(STORAGE_KEYS.firebaseConfig, JSON.stringify(this.dataService.firebaseMasterData));
        }
    }

    fetchConfigVersionForWeb() {
        const remoteConfig = getRemoteConfig();
        remoteConfig.settings = {
            fetchTimeoutMillis: 60000,
            minimumFetchIntervalMillis: 1
          };
        fetchAndActivate(remoteConfig).then((data) => {
            this.retry = 1;
            const firebaseData = localStorage.getItem(STORAGE_KEYS.firebaseConfig);
            let firebaseMasterData;
            if(firebaseData){
              firebaseMasterData = JSON.parse(firebaseData);
            }
            const versionInfo = this.getValueForWeb(remoteConfig,FIREBASE_KEY.configVersion, false);
            if (versionInfo !== firebaseMasterData?.firebaseFetchVersion) {
                    const retryCount = 1;
                    this.fetchConfigForWeb(remoteConfig,retryCount);
                  } else {
                   this.assignDataAndTriggerCalls(firebaseMasterData);
                  }
        }).catch((error: any) => {
                this.loggingService.logMessage('Firebase fetch error: ', error);
                if (++this.retry <= 3) {
                  this.fetchConfigVersionForWeb();
                }
        });
      }

      getValueForWeb(remoteConfig: any, key: string, isJson = true, isBoolean = false) {
        const remoteValue = getValue(remoteConfig, key).asString();
        if (remoteValue) {
          const data = isJson ? Utilities.parseJSONString(remoteValue) : remoteValue;
          return data;
        }
        return null;
      }

      fetchConfigForWeb(remoteConfig: any, retry: any) {
        fetchAndActivate(remoteConfig).then(() => {
         const firebaseMasterData = this.getValueForWeb(remoteConfig,FIREBASE_KEY.firebaseMasterData);
         this.assignDataAndTriggerCalls(firebaseMasterData, true);
        })
          .catch((error: any) => {
            this.loggingService.logMessage('Firebase fetch error: ', error);
            if (++retry <= 3) {
              this.fetchConfigForWeb(remoteConfig, retry);
            }
          });
      }

    private requestFirebaseToken() {
        this.firebase
            .hasPermission()
            .then(permissionResponse => {
                if (permissionResponse) {
                    this.fetchFirebaseToken();
                } else {
                    this.firebase.grantPermission().then(grantPermissionResponse => {
                        if (grantPermissionResponse) {
                            this.fetchFirebaseToken();
                        }
                    });
                }
            })
            .catch(error => {
                this.loggingService.logMessage('FCM token fetch failed with error: ', error);
            });
    }

    private fetchFirebaseToken() {
        this.firebase
            .getToken()
            .then(fcmToken => {
                if (fcmToken) {
                    const dataToBeSent = {
                        fcmToken,
                        platform: this.platform.is(ANDROID)
                            ? ANDROID
                            : IOS,
                        // @ts-ignore
                        deviceId: this.dataService.uuid,
                        // eslint-disable-next-line no-underscore-dangle
                        userId: this.dataService.userProfile._id || ''
                    };
                    this.registerPushNotificationToken(dataToBeSent).subscribe(
                        (registerPushResponse: any) => {
                            if (registerPushResponse.status) {
                            }
                        }
                    );
                }
            })
            .catch(error => {
                this.loggingService.logMessage('firebase getToken error: ', error);
            });
    }

    private onNotificationOpen() {
        this.firebase.onMessageReceived().subscribe((notificationData) => {
            let link = '';
            this.loggingService.logMessage(notificationData, 'onMessageReceived');
            if (notificationData.wzrk_dl) {
                link = notificationData.wzrk_dl.split(':/')[1];
            } else if (notificationData.link) {
                link = notificationData.link.split(':/')[1];
            } else if (notificationData.uri) {
                link = notificationData.uri.split(':/')[1];
            }
            if (notificationData.tap) {
                this.deepLinkService.navToDeepLinkUrl(link);
            }
        }, (error) => {
            this.loggingService.logMessage(error, 'errorOnMessageReceived');
            this.loggingService.logMessage('firebase onMessageReceived: ', error);
        });
    }

    private registerPushNotificationToken(dataToBeSent: RegisterForPushNotificationDataToSend): Observable<object> {
        return this.httpService.post('api/firebase/device-registration', dataToBeSent);
    }
}

