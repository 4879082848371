import { Injectable } from '@angular/core';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Platform } from '@ionic/angular';
import { Utilities } from '../classes/utilities';
import { APPSFLYER, APPSFLYER_PROD_KEY, CLEVERTAP_CHANNELS, FIREBASE_TRACES, IOS } from '../constant';
import { DeepLinkService } from './deep-link.services';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { noop } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InitializePluginService {
    constructor(
        private appsflyer: Appsflyer,
        private platform: Platform,
        private deepLinkService: DeepLinkService,
        private firebase: FirebaseX,
        private firebaseService: FirebaseService,
        private cleverTap: CleverTap
    ) { }

    doInitializePlugin() {
        if (Utilities.isApp()) {
            this.initializeFireBase();
            this.initializeAppsflyer();
            this.initializeCleverTap();
            this.initializeDeepLinkListener();
        }
        this.firebaseService.registerForPushNotification();
    }

    private initializeAppsflyer() {
        const options = {
            devKey: APPSFLYER_PROD_KEY,
            appId: this.platform.is(IOS) ? APPSFLYER.iosAppId : '',
            isDebug: false,
            onInstallConversionDataListener: true
        };
        this.appsflyer.initSdk(options).then(result => {
            const conversionData = Utilities.parseJSONString(result);
            if (conversionData && conversionData.data.is_first_launch === true && conversionData.data.af_status === APPSFLYER.nonOrganic) {
                conversionData.data.deeplink = conversionData.data.af_dp || conversionData.data.af_deeplink || null;
                this.deepLinkService.deepLinkHandler(conversionData.data);
            }
        }).catch((res)=>{
          });
        this.appsflyer.setAppInviteOneLinkID(APPSFLYER.appInviteOneLinkId);
    }

    private initializeFireBase() {
        this.firebase.setAnalyticsCollectionEnabled(true).catch(noop);
        this.firebase.setCrashlyticsCollectionEnabled(true).catch(noop);
        this.firebase.setPerformanceCollectionEnabled(true).catch(noop);
        this.firebase.setAutoInitEnabled(true).catch(noop);
        this.firebase.startTrace(FIREBASE_TRACES.homePageLaunch);
    }

    private initializeCleverTap() {
        this.cleverTap.setDebugLevel(environment.cleverTap.debugLevel).catch(noop);
        this.cleverTap.notifyDeviceReady().catch(noop);
        CLEVERTAP_CHANNELS.forEach((channel) => {
            this.cleverTap.createNotificationChannel(
                channel.id,
                channel.name,
                channel.description,
                channel.importance,
                channel.showBadge
            ).catch(noop);
        });
        this.cleverTap.initializeInbox().catch(noop);
    }

    private initializeDeepLinkListener() {
        document.addEventListener(
            'onDeepLink',
            this.deepLinkService.deepLinkHandler,
            false
        );
    }
}
