import { Component, OnInit, Input } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-product-images-slide',
  templateUrl: './product-images-slide.component.html',
  styleUrls: ['./product-images-slide.component.scss'],
})
export class ProductImagesSlideComponent implements OnInit {
  @Input() public title: string | undefined;
  @Input() public products: any | undefined = [];
  config: SwiperOptions = {
    freeMode: true,
    slidesPerView: 2.5,
    initialSlide: 0,
    spaceBetween: 16,
    slidesOffsetAfter: 16,
    slidesOffsetBefore: 16,
  };
  constructor() { }

  ngOnInit() { }

}
