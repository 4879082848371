import { Injectable } from '@angular/core';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Utilities } from '../classes/utilities';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { noop } from 'rxjs';
import { Platform } from '@ionic/angular';
import { IOS } from '../constant';
import { User } from '../interface';

@Injectable({
    providedIn: 'root'
})
export class RegisterPluginService {
    constructor(
        private appsflyer: Appsflyer,
        private firebase: FirebaseX,
        private cleverTap: CleverTap,
        private platform: Platform
    ) { }

    doRegisterPlugin(user: User) {
        if (Utilities.isApp()) {
            // eslint-disable-next-line no-underscore-dangle
            this.registerAppsflyer(user._id);
            // eslint-disable-next-line no-underscore-dangle
            this.registerFirebase(user._id);
            this.registerCleverTap(user);
        }
    }

    registerAppsflyer(customerId: string | undefined) {
        this.appsflyer.setAppUserId(customerId || '');
    }

    registerFirebase(customerId: string | undefined) {
        this.firebase.setUserId(customerId || '').catch(noop);
        this.firebase.setCrashlyticsUserId(customerId || '').catch(noop);
        this.firebase.setUserProperty('customerId', customerId || '').catch(noop);
    }
    private registerCleverTap(customerDetails: User){
        if (this.platform.is(IOS)) {
            this.firebase.getAPNSToken().then((apnsToken) => {
              if (apnsToken) {
                this.cleverTap.setPushToken(apnsToken).catch(noop);
              }
            }, () => { });
          }
    const userDate = {
            registeredDate : customerDetails.createdAt,
            email: customerDetails.email,
            // eslint-disable-next-line no-underscore-dangle
            customerId: customerDetails._id,
            // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
            Identity: customerDetails._id,
            superActive: customerDetails.isSuper,
            lastRecharge: customerDetails.lastRecharge,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Name: customerDetails.name,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Phone: customerDetails.m_number,
            customerMobile: customerDetails.m_number
        };
          this.cleverTap.enablePersonalization().catch(noop);
          this.cleverTap.registerPush().catch(noop);
          this.cleverTap.onUserLogin(userDate).catch(noop);
    }
}
