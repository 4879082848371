import { Component, Input } from '@angular/core';
import { ModalController, RangeCustomEvent } from '@ionic/angular';
import { HEADER_CONFIG } from '../../../shared/constant';
import { DataService } from '../../services/data.service';
import { NavigateService } from '../../services/navigate.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-farmer-message',
  templateUrl: './farmer-message.component.html',
  styleUrls: ['./farmer-message.component.scss'],
})
export class FarmerMessageComponent {
  @Input() vendorId = '';
  emoji = ['😍','😄','🙂','😔','😡'];
  emojiRange: any = 100;
  comment = '';
  constructor(
    private navigateService: NavigateService,
    private dataService: DataService,
    private toastService: ToastService,
    private modalCtrl: ModalController
    ) { }

  ionViewWillEnter() {
    this.navigateService.header = HEADER_CONFIG.farmerMessage;
    this.navigateService.footer = false;
  }
  onIonChange(ev: Event){
    this.emojiRange = (ev as RangeCustomEvent).detail.value;
  }

  postMessage(){
    const dataToSend = {
      vendor: this.vendorId,
      content: this.comment,
      rating: this.emojiRange
    };
    this.dataService.postFarmerComment(dataToSend).subscribe((success: any)=>{
     if(success.data.message){
      this.toastService.presentToast(success.data.message);
      this.modalCtrl.dismiss();
     }
    });
  }
}
