import { Component, Input } from '@angular/core';
import { CartService } from '../../../cart/services/cart.service';
import { ModalController } from '@ionic/angular';
import { ComponentType } from '../../shared.enum';
import { Utilities } from '../../classes/utilities';


@Component({
  selector: 'app-product-test-case',
  templateUrl: './product-test-case.component.html',
  styleUrls: ['./product-test-case.component.scss'],
})
export class ProductTestCaseComponent {
  @Input() testResultData: any;
  @Input() public whichComponentType: number | undefined;
  componentType = ComponentType;
  previousFooter = false;
  constructor(public cartService: CartService,
    public modalController: ModalController
      ) {
  };

  ionViewWillEnter() {
   Utilities.addClassNameByTagName('body', 'hide-header-footer');
  }

  ionViewWillLeave() {
    Utilities.removeClassNameByTagName('body', 'hide-header-footer');
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

}
