import { Component, Input } from '@angular/core';
import { CartService } from '../../../cart/services/cart.service';
import { ModalController } from '@ionic/angular';
import { SuperCartService } from '../../../organic-kitchen/super-ok-plan/services/super-cart.service';
import { ComponentType } from '../../shared.enum';
import { Utilities } from '../../classes/utilities';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { AddressService } from 'src/app/profile/edit-address/services/address.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-select-quantity',
  templateUrl: './select-quantity.component.html',
  styleUrls: ['./select-quantity.component.scss'],
})
export class SelectQuantityComponent {
  @Input() product: any;
  @Input() public whichComponentType: number | undefined;
  componentType = ComponentType;
  previousFooter = false;
  constructor(public cartService: CartService,
    public modalController: ModalController,
    public superCartService: SuperCartService,
    private authService: AuthService,
    private modalService: ModalService,
    public dataService: DataService,
    private addressService: AddressService
      ) {
  };

  ionViewWillEnter() {
   Utilities.addClassNameByTagName('body', 'hide-footer');
  }

  ionViewWillLeave() {
    Utilities.removeClassNameByTagName('body', 'hide-footer');
  }

  addToCart(productId: string, variantId: string) {
    if(this.authService.isLoggedIn.value){
      if(this.addressService.addressList.length){
        this.cartService.addToCart({ productId, variantId, quantity: 1 });
      }else{
        this.modalService.addAddressModal();
      }
    } else {
      this.modalService.loginAlert();
    }
  }

  increaseCart(productId: string, variantId: string) {
    this.cartService.increase({ productId, variantId, quantity: 1 });
  }

  decreaseCart(productId: string, variantId: string) {
    this.cartService.decrease({ productId, variantId, quantity: 1 });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

}
