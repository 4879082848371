/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router, Event } from '@angular/router';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';
import { HeaderConfig } from '../interface';
import { HEADER_CONFIG } from '../constant';

@Injectable({
    providedIn: 'root'
})
export class NavigateService {
    header: HeaderConfig = HEADER_CONFIG.noHeader;
    footer = false;
    constructor(private router: Router, private location: Location, public navCtrl: NavController) {
     }

    urlMatch(url: string) {
        return this.router.url.match(url);
    }

    navigateForward(url: Array<any>, extras: NavigationExtras | undefined = {}) {
        this.navCtrl.navigateForward(url, extras);
    }

    navigateByUrl(url: string) {
        this.router.navigateByUrl(url);
    }

    navigateBack() {
        this.location.back();
    }

    navigationSetRoot(url: string) {
        this.navCtrl.navigateRoot(url);
    }

    pathMatch(url: string): boolean {
        return this.location.isCurrentPathEqualTo(url);
    }

    pathExist(url: string): boolean {
        return this.router.url.includes(url);
    }

    currentUrl() {
        return this.router.url;
    }
}
