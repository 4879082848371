import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class FarmService {

  constructor(private http: HttpService) { }

  getVendors(productId: string) {
    return this.http.post(`api/vendor/list`, {productId});
  }

  getVendorDetails(productId: string, vendorId: string) {
    return this.http.get(`api/vendor/details/${vendorId}`);
  }

  getVendorComment(vendorId: string) {
    return this.http.get(`api/vendor/comments/list/${vendorId}`);
  }

}
