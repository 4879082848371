import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'app-full-width-banner',
  templateUrl: './full-width-banner.component.html',
  styleUrls: ['./full-width-banner.component.scss'],
})
export class FullWidthBannerComponent{
  @Input() image: any;
  @Input() url: any;
  @Input() public title: string | undefined;

  constructor(
    private navigateService: NavigateService,
    private modalService: ModalService
  ) {
    //do something
  };

  goToPage(url: string) {
    if (/product-detail/i.test(url)) {
      const productId = url.split('id=');
      this.modalService.productDetail(productId[1]);
    }else{
      this.navigateService.navigateByUrl(url);
    }
  }

}
