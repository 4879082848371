import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Utilities } from '../../classes/utilities';
import { DataService } from '../../services/data.service';
import { PAYMENT_OPTION } from '../../shared.enum';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss'],
})
export class PaymentOptionComponent {
  @Input() sodexoEnabled: any;
  paymentOption = PAYMENT_OPTION;
  constructor(
    public modalController: ModalController,
    public dataService: DataService
  ) {
  };

  ionViewWillEnter() {
    Utilities.addClassNameByTagName('body', 'hide-footer');
   }
   ionViewWillLeave() {
     Utilities.removeClassNameByTagName('body', 'hide-footer');
   }

  dismiss(gatewayName = 'new_card') {
    this.modalController.dismiss({
      gatewayName
    });
  }
}
