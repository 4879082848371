import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { BehaviorSubject } from 'rxjs';
import { category } from 'src/app/shared/interface';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categories = new Array();
  categoriesList: category[]= [];
  selectedCategory = new BehaviorSubject('');

  constructor(
    private http: HttpService,
    private platform: Platform) {
    this.platform.ready().then(() => {
     this.getCategories();
    });
   }

  getCategories() {
    this.http.get('v1/category-list?active=yes').subscribe((res: any) => {
      this.categoriesList = res.data.categories;
      this.selectedCategory.next(this.categoriesList[0].name);
    }, (err) => {});
  }

  getSelectedCategory() {
    return this.selectedCategory;
  }
}
