import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Utilities } from '../../classes/utilities';
import { DataService } from '../../services/data.service';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'app-add-address-modal',
  templateUrl: './add-address-modal.component.html',
  styleUrls: ['./add-address-modal.component.scss'],
})
export class AddAddressModalComponent {
  constructor(
    public modalController: ModalController,
    public dataService: DataService,
    private navigateService: NavigateService
  ) {
  };

  ionViewWillEnter() {
    Utilities.addClassNameByTagName('body', 'hide-footer');
   }
   ionViewWillLeave() {
     Utilities.removeClassNameByTagName('body', 'hide-footer');
   }

   navigateToAddAddress(){
    this.modalController.dismiss();
    this.navigateService.navigateByUrl('profile/edit-address');
   }
}
