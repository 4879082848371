import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressService } from '../../../profile/edit-address/services/address.service';
import { HEADER_CONFIG } from '../../constant';
import { Address } from '../../interface';
import { ADD_ADDRESS, LOGIN_PAGE } from '../../routes-constants';
import { NavigateService } from '../../services/navigate.service';

@Component({
  selector: 'app-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss'],
})
export class AddressSelectComponent {
  @Input() url: any;
  addresses: Address[] = [];
  inLoggedInFlow!: boolean;

  constructor(
    private modalCtrl: ModalController,
    private addressService: AddressService,
    private navigateService: NavigateService
  ) {
    //do something
  }

  ionViewWillEnter() {
    this.navigateService.header = HEADER_CONFIG.addDelivery;
    this.navigateService.footer = false;
      this.addresses =  this.addressService.addressList;
  };

  address() {
    this.modalCtrl.dismiss();
    this.navigateService.navigateByUrl(ADD_ADDRESS);
  };

  selected(address: Address) {
    this.modalCtrl.dismiss({
      dismissed: true,
      address,
    });
  }

  checkRoute() {
    if (this.url !== LOGIN_PAGE) {
      this.inLoggedInFlow = false;
      return;
    }
    this.inLoggedInFlow = true;
  }

}

