import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../../../cart/services/cart.service';
import { ModalController } from '@ionic/angular';
import { SelectQuantityComponent } from '../select-quantity/select-quantity.component';
import { Product } from '../../interface';
import { Utilities } from '../../classes/utilities';
import { EventService } from '../../services/events.service';
import { EVENT_ADD_TO_CART } from '../../events-constants';
import { SuperCartService } from 'src/app/organic-kitchen/super-ok-plan/services/super-cart.service';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { AddressService } from 'src/app/profile/edit-address/services/address.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product = {} as Product;
  defaultVariant: any = {};
  imageUrl: string | undefined;
  isNoProductImage = true;

  constructor(
    public cartService: CartService,
    private modalCtrl: ModalController,
    public superCartService: SuperCartService,
    private eventService: EventService,
    public dataService: DataService,
    public authService: AuthService,
    public modalService: ModalService,
    public addressService: AddressService
  ) {}

  async ngOnInit() {
    this.defaultVariant = this.product?.variants.find((el) => el.feature);
    this.transformUrl();
  }

  addToCart(productId: any, variantId: string) {
    if(this.authService.isLoggedIn.value){
      if(this.addressService.addressList.length){
        this.cartService.addToCart({ productId, variantId, quantity: 1 });
        this.eventService.eventsTrack(EVENT_ADD_TO_CART, { productId });
      }else{
       this.modalService.addAddressModal();
      }
    }else{
      this.modalService.loginAlert();
    }
  }

  increaseCart(productId: string, variantId: string) {
    this.cartService.increase({ productId, variantId, quantity: 1 });
  }

  decreaseCart(productId: string, variantId: string) {
    this.cartService.decrease({ productId, variantId, quantity: 1 });
  }

  productDetails() {
    if (!this.superCartService.isEditDelivery) {
      // eslint-disable-next-line no-underscore-dangle
      this.modalService.productDetail(this.product?._id);
    }
  }

  getClass(value: boolean) {
    if (value) {
      return 'stock addToCart';
    } else {
      return 'addToCart';
    }
  }

  async selectQuantity(product: any) {
    if (product.variants.length < 2) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: SelectQuantityComponent,
      cssClass: 'quantity-modal',
      breakpoints: [ 0, 0.42, 0.45 ],
      initialBreakpoint: 0.42,
      animated: true,
      backdropDismiss: true,
      componentProps: { product },
    });

    return await modal.present();
  }
  transformUrl() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.imageUrl = Utilities.transformUrl(this.product?.photo[0]!);
    this.isNoProductImage = false;
  }

}
