import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../interface';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-suggested-products-slide',
  templateUrl: './suggested-products-slide.component.html',
  styleUrls: ['./suggested-products-slide.component.scss'],
})
export class SuggestedProductsSlideComponent implements OnInit {
  @Input() public title: string | undefined;
  @Input() public products: Product[] | undefined = [];
  config: SwiperOptions = {
    freeMode: true,
    slidesPerView:2.2,
    initialSlide: 0,
    spaceBetween: 16,
    slidesOffsetAfter: 30,
    slidesOffsetBefore: 30,
  };
  constructor() {
    //do something
  }
  ngOnInit() {
    //do something
  }
}
