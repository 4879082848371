import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { NavigateService } from '../../services/navigate.service';
import { ActivatedRoute } from '@angular/router';
import { FarmService } from './farm.service';
import { ToastService } from '../../services/toast.service';
import { Vendor } from './farm.interface';
import { ModalController, Platform } from '@ionic/angular';
import { CertificatesPage } from './certificates/certificates.page';
import { GalleryPage } from './gallery/gallery.page';
import { Image, MediaList, Video } from './farm.interface';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ImageViewComponent } from './image-view/image-view.component';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Utilities } from '../../../shared/classes/utilities';
import { LoggingService } from '../../../shared/services/logging.services';
import { HEADER_CONFIG } from '../../../shared/constant';
import { HeaderConfig } from '../../interface';
import { FarmerMessageComponent } from '../farmer-comment/farmer-message.component';
import { ProductTestCaseComponent } from '../product-testcase/product-test-case.component';
declare let google: any;

@Component({
  selector: 'app-farmer-detail',
  templateUrl: './farmer-detail.component.html',
  styleUrls: ['./farmer-detail.component.scss'],
})
export class FarmerDetailComponent {
  @Input() productId: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  @Input() VendorId: any;
  @ViewChild('mapElement', { static: false }) mapElement: any;
  map: any;
  isApp = !this.platform.is('desktop');
  mapOptions = {
    center: { lat: 0, lng: 0 },
    zoom: 14,
    disableDefaultUI: true,
  };
  selectedProduct: any  = {};
  isFarmLocation = false;
  videoPlayer!: ElementRef;
  pause = true;
  type = 'harvest';
  harvestGallery: any = [];
  harvestVideo: Video[] = [];
  certificates: MediaList[] = [];
  harvestImages: Image[] = [];
  farmImages: Image[] = [];
  farmVideo: any = [];
  farmGallery: any = [];
  isHarvest = false;
  isFarm = false;
  images: Image[] = [];
  vendorDetails = {} as Vendor;
  previousHeader: HeaderConfig = HEADER_CONFIG.noHeader;
  previousFooter = false;
  vendorComment: any = [];

  constructor(
    private navigateService: NavigateService,
    public activeRoute: ActivatedRoute,
    public farmService: FarmService,
    public toastService: ToastService,
    private modalCtrl: ModalController,
    private iab: InAppBrowser,
    private file: File,
    private fileOpener: FileOpener,
    private transfer: FileTransfer,
    private loggingService: LoggingService,
    private platform: Platform,

  ) {}

  ionViewWillEnter() {
    this.previousHeader = this.navigateService.header;
    this.previousFooter = this.navigateService.footer;
    this.navigateService.header = HEADER_CONFIG.farmDetails;
    this.navigateService.footer = true;
    this.getVendorDetails();
    this.getVendorComment();
    Utilities.addClassNameByTagName('body', 'remove-space');
   }


  ionViewWillLeave() {
    this.navigateService.header = this.previousHeader;
    this.navigateService.footer = this.previousFooter;
    Utilities.removeClassNameByTagName('body', 'remove-space');

  }

  async messageFarmer() {
    const messageFarmerModal = await this.modalCtrl.create({
      component: FarmerMessageComponent,
      cssClass: 'farmer-message-modal',
      componentProps: {
       vendorId: this.VendorId
      },
    });
    return await messageFarmerModal.present();
  }

  async productTest(testResultData: any) {
    const prop: any = {
      component: ProductTestCaseComponent,
      cssClass: 'product-test-modal',
      animated: true,
      backdropDismiss: true,
      componentProps: {
        testResultData
      },
    };
      prop.breakpoints = [ 0, testResultData.size, 0.95 ];
      prop.initialBreakpoint = testResultData.size;
    const productTestModal = await this.modalCtrl.create(prop);
    return await productTestModal.present();
  }

  playOrPauseVideo() {
    if (this.pause) {
      this.videoPlayer.nativeElement.play();
    } else {
      this.videoPlayer.nativeElement.pause();
    }
    this.pause = !this.pause;
  }

  getVendorDetails() {
    this.farmService.getVendorDetails(this.productId, this.VendorId).subscribe(
      (res: any) => {
        if(res.data.farmer){
          let selectedProduct = {};
          let maxGreen = -1;
          res.data.farmer.products.forEach((element: any) => {
            this.harvestGallery = this.harvestGallery.concat(element.gallery);
            const testParameters = {
              soilHealth : [],
              productPurity: []
            };
            element.processParameter = testParameters;
            this.selectedProduct = element;
            element.maxGreen = 0;
            element.testParameters.forEach((parameter: any) => {
              if(parameter.type === 'Soil Health'){
                element.processParameter.soilHealth.push(parameter);
              }else{
                element.processParameter.productPurity.push(parameter);
              }
              if(this.productId === element.productId ){
                element.maxGreen = 10;
              }else if(parameter.displayColor === '#85BF44'){
                  element.maxGreen++;
              }
            });
            if(maxGreen < element.maxGreen){
              selectedProduct = element;
              maxGreen = element.maxGreen;
            }
          });
           this.selectedProduct = selectedProduct;
          this.vendorDetails = res.data.farmer;
          this.vendorDetails.certificates = this.vendorDetails.certificates.concat(this.vendorDetails.labReports);
          if (
            'lat' in this.vendorDetails.address &&
            'lng' in this.vendorDetails.address
          ) {
            this.farmLocation();
          }
        }
      },
      (error) => {
        this.toastService.presentErrorToast(error);
      }
    );
  }

  setLinearStyle(color: string){
    return {
      background : 'linear-gradient(to right, ' + color + ' 50%, transparent 0)',
      'background-position': 'right',
      'background-size': '200% 100%',
      animation: 'newTestIn 4s 1s forwards'
    };
  }

  getVendorComment(){
    this.farmService.getVendorComment(this.VendorId).subscribe((success: any)=>{
      if(success.data.commentList){
        this.vendorComment = success.data.commentList;
      }
    });
  }

  farmLocation() {
    this.mapOptions.center.lat = this.vendorDetails.address.lat;
    this.mapOptions.center.lng = this.vendorDetails.address.lng;
    if (this.mapOptions.center.lat && this.mapOptions.center.lng) {
      this.isFarmLocation = true;
      const googleMap = new google.maps.Map(
        this.mapElement.nativeElement,
        this.mapOptions
      );
      this.map = new google.maps.Marker({
        position: this.mapOptions.center,
        map: googleMap,
        icon: '../../assets/icon/add-address/locationpin.svg',
        title: `${this.vendorDetails.name}`,
      });
    }
  }

  async certificatesAll() {
    const modal = await this.modalCtrl.create({
      component: CertificatesPage,
      componentProps: {
        certificates: this.vendorDetails.certificates,
      },
    });
    return await modal.present();
  }

  async gallery() {
    const modal = await this.modalCtrl.create({
      component: GalleryPage,
      componentProps: {
        images: this.farmGallery,
      },
    });
    return await modal.present();
  }

  async harvest() {
    const modal = await this.modalCtrl.create({
      component: GalleryPage,
      componentProps: {
        images: this.harvestGallery,
      },
    });
    return await modal.present();
  }

  segmentChanged(segment: any) {
    this.type = segment.detail.value;
  }


  fileOpen(certificate: any) {
    const fileTransfer = this.transfer.create();
    if (Utilities.isApp()) {
      // eslint-disable-next-line no-underscore-dangle
      const fileName = `${this.file.dataDirectory}${certificate._id}${
        certificate.mediaType === 'PDF' ? '.pdf' : '.jpeg'
      }`;
      fileTransfer.download(certificate.url, fileName).then(
        (fileEntry: FileEntry) => {
          this.fileOpener
            .open(
              fileEntry.nativeURL,
              `${
                certificate.mediaType === 'PDF'
                  ? 'application/pdf'
                  : 'image/jpeg'
              }`
            )
            .then(() => {})
            .catch((error) => {
              this.loggingService.logMessage(error, 'fileOpenerError');
            });
        },
        (error) => {
          this.loggingService.logMessage(error, ' fileTransfer.downloadError');
        }
      );
    } else {
      this.iab.create(`${certificate.url}`, '_blank', 'location=yes');
    }
  }

  async imageView(images: any, index: any) {
    const modal = await this.modalCtrl.create({
      component: ImageViewComponent,
      cssClass: 'my-custom-class',
      componentProps: { images, index },
    });
    return await modal.present();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}

