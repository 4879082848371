import { Injectable, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CategoryService } from '../component/category/services/category.service';
import {
    ADD_ADDRESS, BUY_SUPER, HOME, LOGIN_PAGE,
    OK_SUPER_PLAN_HISTORY, ORDER_DETAIL_ORDER_ID, PRODUCT_DETAIL_PRODUCT_ID, PRODUCT_SEARCH, PROFILE,
    PROFILE_ORDER, PROFILE_WALLET, RENEW_PLAN, SHOP,
    SIGN_UP, SUPER, SUPER_HOME
} from '../routes-constants';
import {ModalService} from './modal.service';


@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {

    deepLinkOnAppLaunch: string | undefined;
    constructor(
        private navCtrl: NavController,
        private zone: NgZone,
        private modalService: ModalService,
        private categoryService: CategoryService
    ) { }

    deepLinkHandler = (data: any) => {
        let redirectUrl;
        if (data.redirect_url) {
            redirectUrl = data.redirect_url;
        }
        if (data.link) {
            redirectUrl = data.link.split(':/')[1];
        } else if (data.path) {
            redirectUrl = data.path;
        }
        this.callNavToDeepLinkUrl(redirectUrl);
    };

    navToDeepLinkUrl = (url: string) => {
        let navigateUrl = '';
        if (/home/i.test(url)) {
            navigateUrl = HOME;
        } else if (/login/i.test(url)) {
            navigateUrl = LOGIN_PAGE;
        } else if (/sign-up/i.test(url)) {
            navigateUrl = SIGN_UP;
        } else if (/product-search/i.test(url)) {
            navigateUrl = PRODUCT_SEARCH;
        } else if (/shop-category/i.test(url)) {
            const category = url.split('/');
            this.categoryService.selectedCategory.next(category[3]);
            navigateUrl = `${SHOP}`;
        } else if (/buy-super/i.test(url)) {
            const planType = url.split('/');
            navigateUrl = `${BUY_SUPER}/${planType[3]}`;
        }  else if (/profile-order/i.test(url)) {
          navigateUrl = PROFILE_ORDER;
        } else if (/profile/i.test(url)) {
          navigateUrl = PROFILE;
        } else if (/add-address/i.test(url)) {
          navigateUrl = ADD_ADDRESS;
        } else if (/order-detail/i.test(url)) {
          const orderId = url.split('/');
          navigateUrl = `${ORDER_DETAIL_ORDER_ID}${orderId[3]}`;
        } else if (/product-detail/i.test(url)) {
          const productId = url.split('/');
          this.modalService.productDetail(productId[3]);
        } else if (/super-ok-plan/i.test(url)) {
          navigateUrl = SUPER_HOME;
        } else if (/super-ok-history/i.test(url)) {
          navigateUrl = OK_SUPER_PLAN_HISTORY;
        } else if (/renew-plan/i.test(url)) {
          navigateUrl = RENEW_PLAN;
        } else if (/super/i.test(url)) {
          navigateUrl = SUPER;
        } else if (/add-money/i.test(url)) {
          navigateUrl = PROFILE_WALLET;
        }
        if (navigateUrl) {
            this.zone.run(() => {
                setTimeout(() => {
                    this.navCtrl.navigateForward(navigateUrl);
                });
            });
        }
    };

    callNavToDeepLinkUrl = (redirectUrl: string) => {
        if (redirectUrl) {
            this.deepLinkOnAppLaunch = redirectUrl;
            this.navToDeepLinkUrl(redirectUrl);
        }
    };

}


