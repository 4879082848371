import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageViewComponent } from '../image-view/image-view.component';
import { NavigateService } from 'src/app/shared/services/navigate.service';
import { HEADER_CONFIG } from 'src/app/shared/constant';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss'],
})
export class GalleryPage {
  @Input() images: any = [];

  constructor(
    public modalController: ModalController,
    private navigateService: NavigateService) {
    }
    ionViewWillEnter(){
      this.navigateService.header = HEADER_CONFIG.farmGallery;
      this.navigateService.footer = false;
  }
  ionViewWillLeave(){
    this.navigateService.header = HEADER_CONFIG.farmImage;
    this.navigateService.footer = false;
}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async imageView(images: any, index: any) {
    const modal = await this.modalController.create({
      component: ImageViewComponent,
      cssClass: 'my-custom-class',
      componentProps: { images, index }
    });
    return await modal.present();
  }

}
