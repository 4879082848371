/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Product } from '../../../interface';
import { Utilities } from '../../../classes/utilities';

@Injectable({
  providedIn: 'root'
})
export class SocialShareService {

  constructor(
    private socialSharing: SocialSharing
  ) { }

  sShareApp(url = 'https://organickitchen.onelink.me/ef2T/3cj4u9g0') {
    const options: any = {
      message: 'Download OK India', // not supported on some apps (Facebook, Instagram)
      url,
      chooserTitle: 'Share App'
    };
    this.socialSharing.shareWithOptions(options);
  }

  shareInstagram(product: Product) {
    this.socialSharing.shareViaInstagram(`Buy (${product.name}) from OK India and get it delivered straight to your home.`, `${product.photo[0]}`);
  }

  shareWhatsapp(product: Product) {
    this.socialSharing.shareViaWhatsApp(`Buy (${product.name}) from OK India and get it delivered straight to your home.`, `${product.photo[0]}`, 'https://play.google.com/store/apps/details?id=com.prasuk.organics&hl=en_IN&gl=US');
  }

  shareFacebook(product: Product) {
    this.socialSharing.shareViaFacebook(`Buy (${product.name}) from OK India and get it delivered straight to your home.`, `${product.photo[0]}`);
  }

}
