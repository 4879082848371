import { Component, Input } from '@angular/core';
import { Vendor } from '../farmer-detail/farm.interface';
import { ModalService } from '../../services/modal.service';
import { ComponentType } from '../../shared.enum';
import { CartService } from 'src/app/cart/services/cart.service';
import { Utilities } from '../../classes/utilities';
import { isPlatform } from '@ionic/angular';

@Component({
  selector: 'app-farmer-card',
  templateUrl: './farmer-card.component.html',
  styleUrls: ['./farmer-card.component.scss'],
})
export class FarmerCardComponent {
  @Input() vendors: Array<Vendor>  = [];
  @Input() searchName= '';
  @Input() public whichComponentType: number | undefined;
  @Input() productId = '';
  componentType = ComponentType;
  utilities = Utilities;
  farmerCard = {
    slidesPerView: 3,
    slides: {
      el: Utilities.isApp(),
      perView: 2.1,
  },
    initialSlide: 0,
    spaceBetween: 0,
    slidesOffsetAfter:0,
    slidesOffsetBefore: 0,
  };
  constructor(
    public modalService: ModalService,
    public cartService: CartService
  ) { }

  farmDetails(vendorId: string, event?: any) {
    if(event){
      event.stopPropagation();
    }
    this.modalService.farmerDetail(this.productId, vendorId);
  }

}
