import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProductCardComponent } from './component/product-card/product-card.component';
import { HeaderComponent } from './component/header/header.component';
import { PromosComponent } from './component/promos/promos.component';
import { CategoryComponent } from './component/category/category.component';
import { SuggestedProductsComponent } from './component/suggested-products/suggested-products.component';
import { FooterComponent } from './component/footer/footer.component';
import { FullWidthBannerComponent } from './component/full-width-banner/full-width-banner.component';
import { RouterModule } from '@angular/router';
import { SuggestedProductsSlideComponent } from './component/suggested-products-slide/suggested-products-slide.component';
import { SelectQuantityComponent } from './component/select-quantity/select-quantity.component';
import { AddressSelectComponent } from './component/address-select/address-select.component';
import { PageCollectionComponent } from './component/page-collection/page-collection.component';
import { LowestPriceComponent } from './component/lowest-price/lowest-price.component';
import { ProductDayComponent } from './component/product-day/product-day.component';
import { EmptyscreenComponent } from './component/emptyscreen/emptyscreen.component';
import { ProductImagesSlideComponent } from './component/product-images-slide/product-images-slide.component';
import { SocialShareComponent } from './component/social-share/social-share.component';
import { ProductFarmSlideComponent } from './component/product-farm-slide/product-farm-slide.component';
import { AppVersionComponent } from './component/app-version/app-version.component';
import { SwiperModule } from 'swiper/angular';
import { WhatsappAlertComponent } from './component/whatsapp-alert/whatsapp-alert.component';
import { SubscriptionComponent } from './component/subscription/subscription.component';
import { ProductDetailComponent } from './component/product-detail/product-detail.component';
import { FarmerDetailComponent } from './component/farmer-detail/farmer-detail.component';
import { CertificatesPage } from './component/farmer-detail/certificates/certificates.page';
import { GalleryPage } from './component/farmer-detail/gallery/gallery.page';
import { ImageViewComponent } from './component/farmer-detail/image-view/image-view.component';
import { PaymentOptionComponent } from './component/payment-options/payment-options.component';
import { PaymentScreenComponent } from './component/payment-screen/payment-screen.component';
import { PaymentSaveCardComponent } from './component/payment-save-card/payment-save-card.component';
import { LoginAlertComponent } from './component/login-alert/login-alert.component';
import { AddAddressModalComponent } from './component/add-address-modal/add-address-modal.component';
import { FarmerCardComponent } from './component/farmer-card/farmer-card.component';
import { OkSuperStripComponent } from './component/ok-super-strip/ok-super-strip.component';
import { FilterPipe } from './pipe/search-farmer.pipe';
import { FarmerFilterComponent } from './component/farmer-filter/farmer-filter.component';
import { FarmerMessageComponent } from './component/farmer-comment/farmer-message.component';
import { ProductTestCaseComponent } from './component/product-testcase/product-test-case.component';
import { SecurityMessageComponent } from './component/security-message/security-message.component';
import { LowBalancePopupComponent } from './component/low-balance-popup/low-balance-popup';



@NgModule({
  declarations: [
    ProductCardComponent,
    HeaderComponent,
    PromosComponent,
    CategoryComponent,
    SuggestedProductsComponent,
    FooterComponent,
    FullWidthBannerComponent,
    SuggestedProductsSlideComponent,
    SelectQuantityComponent,
    AddressSelectComponent,
    PageCollectionComponent,
    LowestPriceComponent,
    ProductDayComponent,
    EmptyscreenComponent,
    ProductImagesSlideComponent,
    SocialShareComponent,
    ProductFarmSlideComponent,
    AppVersionComponent,
    WhatsappAlertComponent,
    SubscriptionComponent,
    ProductDetailComponent,
    FarmerDetailComponent,
    CertificatesPage,
    GalleryPage,
    ImageViewComponent,
    PaymentOptionComponent,
    PaymentScreenComponent,
    PaymentSaveCardComponent,
    LoginAlertComponent,
    AddAddressModalComponent,
    FarmerCardComponent,
    OkSuperStripComponent,
    FilterPipe,
    FarmerFilterComponent,
    FarmerMessageComponent,
    ProductTestCaseComponent,
    SecurityMessageComponent,
    LowBalancePopupComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    SwiperModule,
    ReactiveFormsModule,
  ],
  exports: [
    ProductCardComponent,
    HeaderComponent,
    PromosComponent,
    CategoryComponent,
    SuggestedProductsComponent,
    FooterComponent,
    FullWidthBannerComponent,
    SuggestedProductsSlideComponent,
    SelectQuantityComponent,
    AddressSelectComponent,
    PageCollectionComponent,
    LowestPriceComponent,
    ProductDayComponent,
    EmptyscreenComponent,
    ProductImagesSlideComponent,
    SocialShareComponent,
    ProductFarmSlideComponent,
    AppVersionComponent,
    WhatsappAlertComponent,
    CertificatesPage,
    GalleryPage,
    ImageViewComponent,
    PaymentSaveCardComponent,
    FarmerCardComponent,
    OkSuperStripComponent,
    FarmerFilterComponent,
    FarmerMessageComponent,
    ProductTestCaseComponent,
    SecurityMessageComponent,
    LowBalancePopupComponent
  ],
})
export class SharedModule {}
