import { Component, OnInit, Input } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Utilities } from 'src/app/shared/classes/utilities';

@Component({
  selector: 'app-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
})
export class AppVersionComponent implements OnInit {
  @Input() message = '';
  @Input() url = '';
  constructor(private iab: InAppBrowser) { }

  ngOnInit() { }

  update() {
    if (Utilities.platform === 'ios') {
      this.iab.create(this.url, '_blank', 'location=yes');
    }
    else {
      window.open(this.url, '_system', 'location=yes');
    }
  }
}
