import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HEADER_CONFIG } from 'src/app/shared/constant';
import { NavigateService } from 'src/app/shared/services/navigate.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
import { PlatformLocation } from '@angular/common';


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent implements OnInit {
  @Input() images: any;
  @Input() index: any;
  config: SwiperOptions = {
    freeMode: true,
    slidesPerView: 1,
    loop: false,
    navigation: true,
    scrollbar: { draggable: true },
  };
  constructor(
    public modalController: ModalController,
    private navigateService: NavigateService) {
  }
  ionViewWillEnter(){
    this.navigateService.header = HEADER_CONFIG.farmImage;
    this.navigateService.footer = false;
}
  ionViewWillLeave(){
  this.navigateService.header = HEADER_CONFIG.farmDetails;
  this.navigateService.footer = false;
  }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
