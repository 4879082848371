import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Utilities } from '../../../shared/classes/utilities';

@Component({
  selector: 'app-security-message',
  templateUrl: './security-message.component.html',
  styleUrls: ['./security-message.component.scss'],
})
export class SecurityMessageComponent {
  constructor(
    public modalController: ModalController,
  ) {
  };

  ionViewWillEnter() {
    Utilities.addClassNameByTagName('body', 'hide-footer');
   }
   ionViewWillLeave() {
     Utilities.removeClassNameByTagName('body', 'hide-footer');
   }

}
