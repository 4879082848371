declare let window: any;

export class Utilities {
    constructor() {
    }

    static get platform() {
        return window.cordova.platformId;
    }

    static isApp(): boolean {
        return window.cordova.platformId !== 'browser';
    }

    static getElementsByTagName(tagName: string): any {
        const tagValue = document.getElementsByTagName(tagName);
        return tagValue[tagValue.length - 1];
    }

    static addClassNameByTagName(tagName: string, className: string): any {
        const tagValue = document.getElementsByTagName(tagName);
        tagValue[tagValue.length - 1].classList.add(
            className
          );
      }

      static removeClassNameByTagName(tagName: string, className: string): any {
        const tagValue = document.getElementsByTagName(tagName);
        tagValue[tagValue.length - 1].classList.remove(
            className
          );
      }

    static createRandom4DigitNumber() {
        return Math.floor(Math.random() * 10000);
    }

    static replaceKeysWithValues(message: string, contentKeysToBeReplaced: any = {}) {
        for (const key in contentKeysToBeReplaced) {
            if (contentKeysToBeReplaced.hasOwnProperty(key)) {
                message = message.replace(
                    new RegExp(`{${key}}`, 'ig'),
                    contentKeysToBeReplaced[key]
                );
            }
        }
        return message;
    }

    // parse json string if exist and valid else return null
    static parseJSONString(jsonString: string, isDoubleParseRequired = false) {
        try {
            return JSON.parse(isDoubleParseRequired ? JSON.parse(jsonString) : jsonString);
        } catch (e) {
            return null;
        }
    }

    static transformUrl(url: string) {
        const screeMain = screen.width;
        if( screeMain <= 568 ){
            const splitList = url.split('/');
            splitList[splitList.length - 1] = '200x200/' + splitList[splitList.length - 1];
            return splitList.join('/');
        }
        else{
            return url;
        }
    }
}
