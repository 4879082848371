import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // private _storage: Storage | null = null;

  constructor() {
    // this.init();
  }

  setSuperCart(cartType: string, cartProducts: any) {
    localStorage.setItem(cartType, JSON.stringify(cartProducts));
  }

  // async init() {
  //   const storage = await this.storage.create();
  //   this._storage = storage;
  // }


  // public set(key: string, value: any) {
  //   this._storage?.set(key, value);
  // }

  // public get(key: string) {
  //   return this._storage?.get(key);
  // }

  // async addItem(key: string, productId: string, variantId: string) {
  //   const storedItemList = await this._storage?.get(key) || [];
  //   let index = storedItemList.findIndex((item: any) => item.productId == productId && item.variantId == variantId);
  //   if (index != -1) storedItemList[ index ][ 'quantity' ]++;
  //   else storedItemList.push({ 'productId': productId, 'variantId': variantId, 'quantity': 1 });
  //   return this._storage?.set(key, storedItemList);
  // }

  // async decreaseItem(key: string, productId: string, variantId: string) {
  //   const storedItemList = await this._storage?.get(key) || [];
  //   let index = storedItemList.findIndex((item: any) => (item.productId == productId) && (item.variantId == variantId));
  //   if (storedItemList[ index ][ 'quantity' ] != 0) storedItemList[ index ][ 'quantity' ]--;
  //   if (storedItemList[ index ][ 'quantity' ] == 0) storedItemList.splice(index, 1);
  //   return this._storage?.set(key, storedItemList);
  // }

}
