import { Component, OnInit, Input } from '@angular/core';
import { MediaList } from '../farm.interface';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HEADER_CONFIG } from 'src/app/shared/constant';
import { NavigateService } from 'src/app/shared/services/navigate.service';
@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.page.html',
  styleUrls: ['./certificates.page.scss'],
})
export class CertificatesPage {
  @Input() certificates: MediaList[] = [];
  directory: any;
  constructor(
    public modalController: ModalController,
    private navigateService: NavigateService,
    private iab: InAppBrowser
  ) {
  }
  ionViewWillEnter(){
    this.navigateService.header = HEADER_CONFIG.super;
    this.navigateService.footer = false;
}
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  fileOpen(certificate: any) {
    if (certificate.mediaType === 'PDF') {
      const url = encodeURIComponent(`${certificate.url}`);
      const ref = this.iab.create('https://docs.google.com/viewer?url=' + url);
    } else {
      const ref = this.iab.create(`${certificate.url}`, '_blank', 'location=yes');
    }
  }
}
