import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddAddressModalComponent } from '../component/add-address-modal/add-address-modal.component';
import { FarmerDetailComponent } from '../component/farmer-detail/farmer-detail.component';
import { LoginAlertComponent } from '../component/login-alert/login-alert.component';
import { LowBalancePopupComponent } from '../component/low-balance-popup/low-balance-popup';
import { PaymentOptionComponent } from '../component/payment-options/payment-options.component';
import { PaymentSaveCardComponent } from '../component/payment-save-card/payment-save-card.component';
import { PaymentScreenComponent } from '../component/payment-screen/payment-screen.component';
import { ProductDetailComponent } from '../component/product-detail/product-detail.component';
import { SecurityMessageComponent } from '../component/security-message/security-message.component';
import { PaymentScreenContent } from '../interface';
import { PAYMENT_OPTION } from '../shared.enum';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalController: ModalController,  private http: HttpService) {}

  async productDetail(productId: string) {
    const productDetailModal = await this.modalController.create({
      component: ProductDetailComponent,
      cssClass: 'product_details',
      componentProps: { productId },
    });
    return await productDetailModal.present();
  }

  async farmerDetail(productId: string, vendorId: string) {
    const farmerDetailModal = await this.modalController.create({
      component: FarmerDetailComponent,
      cssClass: 'farmer_certificate-details',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      componentProps: { productId, VendorId: vendorId },
    });
    return await farmerDetailModal.present();
  }

  async paymentScreen(paymentScreen: PaymentScreenContent) {
    const farmerDetailModal = await this.modalController.create({
      component: PaymentScreenComponent,
      cssClass: 'payment-screen',
      componentProps: { paymentScreen },
    });
    return await farmerDetailModal.present();
  }

  async loginAlert() {
    const termModal = await this.modalController.create({
      component: LoginAlertComponent,
      cssClass: 'login-alert-class',
      breakpoints: [ 0, 0.45, 0.85 ],
      initialBreakpoint: 0.40,
      componentProps: { },
    });
    return await termModal.present();
  }

  async addAddressModal() {
    const addAddressModal = await this.modalController.create({
      component: AddAddressModalComponent,
      cssClass: 'add-address-Modal',
      breakpoints: [ 0, 0.42, 0.85 ],
      initialBreakpoint: 0.40,
      componentProps: { },
    });
    return await addAddressModal.present();
  }

  async saveCard(carList: any, callBack: any, dataToSend: any) {
    const saveCardModal = await this.modalController.create({
      component: PaymentSaveCardComponent,
      cssClass: 'save-card-class',
      componentProps: {carList },
    });
    await saveCardModal.present();
    saveCardModal.onDidDismiss().then((sourceId: any) => {
      if(sourceId.data){
        if(sourceId.data.sourceId === 'new_card'){
          callBack(dataToSend);
        }else{
          dataToSend.sourceId = sourceId.data.sourceId;
          callBack(dataToSend);
        }
      }
    });
  }

  async paymentOptions(callBack: any, data: any, sodexoEnabled = true) {
    const farmerDetailModal = await this.modalController.create({
      component: PaymentOptionComponent,
      cssClass: 'payment-modal',
      breakpoints: [ 0, 0.28, 0.65 ],
      initialBreakpoint: 0.28,
      componentProps: { sodexoEnabled },
    });
    await farmerDetailModal.present();
    farmerDetailModal.onDidDismiss().then((gatewayName: any) => {
      if(gatewayName.data && gatewayName.data.gatewayName){
        const dataToSend = Object.assign(data, {gatewayName: gatewayName.data.gatewayName});
        if(gatewayName.data.gatewayName  === PAYMENT_OPTION.SODEXO){
          this.getSavedCard(callBack, dataToSend);
        }else{
          callBack(dataToSend);
        }
      }
    });
  }

  getSavedCard(callBack: any, dataToSend: any){
    this.http.get('api/payment/sodexo/list-card').subscribe(
      (res: any)=>{
        if(res.data.cardList.length){
          this.saveCard(res.data.cardList, callBack, dataToSend);
        }else{
          callBack(dataToSend);
        }
      }
    );
  }
  async securityMessage() {
    const securityMessageModal = await this.modalController.create({
      component: SecurityMessageComponent,
      cssClass: 'securityMessageModal',
      breakpoints: [ 0, 0.49, 0.85 ],
      initialBreakpoint: 0.49,
      animated: true,
      backdropDismiss: true,
    });
    return await securityMessageModal.present();
  }
  async lowBalancePopup() {
    const lowBalancePopup = await this.modalController.create({
      component: LowBalancePopupComponent,
      cssClass: 'lowBalancePopup',
      breakpoints: [ 0, 0.75, 0.85 ],
      initialBreakpoint: 0.75,
      animated: true,
      backdropDismiss: true,
    });
    return await lowBalancePopup.present();
  }
}
