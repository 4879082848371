import { Injectable } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class SuperOkService {
  constructor(private http: HttpService) { }

  purchasePlan(planId: number) {
    const plan = { planId };
    return this.http.post('api/super', plan);
  }

  checkSuperOk() {
    return this.http.get('api/mySuper');
  }

  planDetails() {
    return this.http.get('api/super-v2');
  }

  deliveryDetail(id: string) {
    return this.http.get(`api/super/delivery-v2/${id}`);
  }

  removeDelivery(id: string) {
    return this.http.delete(`api/super/delivery/${id}`);
  }

  buyOkDetails(id: number) {
    return this.http.get(`api/superPlan/${id}`);
  }

  updateDelivery(orderDetail: any) {
    return this.http.put('api/order/edit', orderDetail);
  }

  addSuperMoney(data: any) {
    return this.http.post('api/super/addBalance', data);
  }

  superTransaction() {
    return this.http.get('api/super/balanceHistory');
  }

  extendValidity() {
    return this.http.post('api/super/extendValidity', {});
  }

  calculatePrice(calculationObj: any) {
    return this.http.post('api/super-delivery/calculation', calculationObj);
  }

  getActiveSuper(){
    return this.http.get('api/mySuper');
  }

}
