import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  city = new BehaviorSubject<any>('Gurugram');

  constructor() {
    this.getCity();
  }

  getCity() {
    const selectCity = localStorage.getItem('city');
    this.city.next(selectCity);
    return this.city.value;
  }

  updateCity(city: string) {
    localStorage.setItem('city', city);
    this.city.next(city);
  }
}
