import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HomeService } from '../../../home/services/home.service';
import { Banner } from '../../interface';
import { NavigateService } from '../../services/navigate.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions, Autoplay } from 'swiper';
import { ToastService } from '../../services/toast.service';
import { DataService } from '../../services/data.service';
import { ModalService } from '../../services/modal.service';
import { Utilities } from '../../classes/utilities';


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.scss'],
})
export class PromosComponent implements OnInit {
  @Input() public title = '';
  config: SwiperOptions = {
    freeMode: false,
    slidesPerView: 1.3,
    spaceBetween: 30,
    initialSlide: 0,
    slidesOffsetAfter: 30,
    slidesOffsetBefore: 30,
    autoplay: {
      delay: 4000000,
      disableOnInteraction: false
    }
  };
  deskConfig: SwiperOptions = {
    freeMode: true,
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: 0,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    }
  };

  banners: Banner[] = [];

  constructor(
    private homeService: HomeService,
    private navigateService: NavigateService,
    private platform: Platform,
    private toastService: ToastService,
    public dataService: DataService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.getBanner();
    });
  }

  getBanner() {
    this.homeService.banner().subscribe((res: any) => {
      this.banners = res.data.banners;
    }, (err) => {
      this.toastService.presentErrorToast(err);
    });
  };

  bannerRedirect(url: string) {
    if (/product-detail/i.test(url)) {
      const productId = url.split('id=');
      this.modalService.productDetail(productId[1]);
    }else{
      this.navigateService.navigateByUrl(url);
    }
  }

}
