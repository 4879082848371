import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpService) { }

  product(id: any){
    return this.http.get(`v1/product/${id}`);
  }

  productList(id: string |undefined){
    return this.http.get(`api/productList?pageCollectionId=${id}`);
  }
}
